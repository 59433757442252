export const BankAccountType = {
  Checking: 'CHECKING',
  Savings: 'SAVINGS',
  Unspecified: 'BANK_ACCOUNT_TYPE_UNSPECIFIED',
}

export const TaxRefundDeliveryMethodType = {
  DirectDeposit: 'DIRECT_DEPOSIT',
  Check: 'CHECK',
  FutureTaxCredit: 'FUTURE_TAX_CREDIT',
  Unspecified: 'UNSPECIFIED',
}

export const TaxRefundType = {
  Personal: 'PERSONAL',
  Business: 'BUSINESS',
  Unspecified: 'TAX_REFUND_TYPE_UNSPECIFIED',
}

export const TaxRefundTypeCopy = {
  PERSONAL: {
    mailingAddressInfoLine: 'Using same address as personal information.',
    directDepositInputText:
      'Provide your personal bank account information upfront. The tax preparer will share your account details with the IRS, to pay out refunds automatically.',
    checkFormRadioInputText:
      'Use same address in personal information for delivery?',
  },
  BUSINESS: {
    mailingAddressInfoLine: 'Using same address as personal information.',
    directDepositInputText:
      'Provide your business bank account information upfront. The tax preparer will share your account details with the IRS, to pay out refunds automatically.',
    checkFormRadioInputText:
      'Use same address in business information for delivery?',
  },
}
