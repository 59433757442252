import { Store } from 'libx'
import LogRocket from 'logrocket'
import env from 'misc/env'

export default class TrackingStore extends Store {
  constructor(props) {
    super(props)

    // RudderStack
    if (env.RUDDERSTACK_WRITE_KEY && env.RUDDERSTACK_DATA_PLANE_URL) {
      window.rudderanalytics &&
        window.rudderanalytics.load(
          env.RUDDERSTACK_WRITE_KEY,
          env.RUDDERSTACK_DATA_PLANE_URL,
          {}
        )
    }

    // Identifies user upon workspace selection
    this.rootStore.sessionStore.onWorkspaceSelected((workspace, member) => {
      this.identify(member.userId, {
        first_name: member.givenName,
        last_name: member.familyName,
        email: member.email,
        phone: member.phone,
        workspace_id: member.workspaceId,
        public_id: member.userPublicId,
      })

      this.trackRudderAnalytics('workspace login')

      LogRocket.identify(member.userId, {
        name: `${member.givenName} ${member.familyName}`,
        email: member.email,

        // Add your own custom user variables here, ie:
        workspaceId: member.workspaceId,
      })

      // have Oribi track user email
      window.ORIBI && window.ORIBI.api('setUserEmail', member.email)

      this.rootStore.hubspotChatStore.maybeActivateAndLoadWidget(
        workspace,
        member
      )
    })
  }

  isImpersonating() {
    try {
      return this.rootStore.authStore.user.impersonated
    } catch (err) {
      return false
    }
  }

  // Handles identifying, tracking, and paging
  identify(userId, data) {
    if (this.isImpersonating()) {
      return
    }

    try {
      this.setDataLayer('identify', {
        userId: userId,
        email: data.email,
        workspaceId: data.workspace_id,
      })

      window.heap.identify(userId)
      window.heap.addUserProperties({
        'First Name': data.first_name,
        'Last Name': data.last_name,
        Email: data.email,
      })

      if (data.workspace_id) {
        window.rudderanalytics &&
          window.rudderanalytics.identify(data.public_id, {
            firstName: data.first_name,
            lastName: data.last_name,
            email: data.email,
            phone: data.phone,
            workspaceId: data.workspace_id,
          })
      }
    } catch (error) {
      console.error('Error identifying user', error)
    }
  }

  track(action, data) {
    if (this.isImpersonating()) {
      return
    }

    const sessionStore = this.rootStore.sessionStore
    if (sessionStore.workspace && sessionStore.workspace.id === '4') {
      action += ' - Onboarding'
    } else if (sessionStore.workspace && sessionStore.workspace.id !== '1') {
      action += ' - Worklayer'
    }
  }

  page(pageName) {
    // if (this.isImpersonating()) {
    //   return
    // }
  }

  // Configures data for Google Tag Manager
  setDataLayer(event, data) {
    if (window.dataLayer) {
      data = {
        ...data,
        event: event,
      }

      if (process.env.NODE_ENV === 'production') {
        window.dataLayer.push(data)
      }
    }
  }

  // Callback for use in the WorkspaceSelectorStore
  accountSignup(data) {
    if (this.isImpersonating()) {
      return
    }

    this.identify(data.user_id, {
      first_name: data.given_name,
      last_name: data.family_name,
      email: data.email,
      phone: data.phone,
      workspace_id: data.workspace_id,
      public_id: data.user_public_id,
    })

    this.trackRudderAnalytics('signup')

    this.setDataLayer('accountSignup', {
      firstName: data.given_name,
      lastName: data.family_name,
      email: data.email,
    })

    if (window.growsumo) {
      const publicId = this.rootStore.sessionStore.user.userPublicId
      window.growsumo.data.name = `${data.given_name} ${data.family_name}`
      window.growsumo.data.email = data.email
      window.growsumo.data.customer_key = publicId ?? data.user_id

      window.growsumo.createSignup()
    }
  }

  trackRudderAnalytics(eventMessage, data = {}) {
    if (this.isImpersonating()) {
      return
    }

    try {
      window.rudderanalytics && window.rudderanalytics.track(eventMessage, data)
    } catch (error) {
      console.error(`Error tracking ${eventMessage}`, error)
    }
  }
}
