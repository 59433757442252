import { action, observable, computed } from 'mobx'
import { task } from 'mobx-task'
import { validationContext, required } from 'validx'
import { extractMessageFromError } from 'utils/errorUtil'
import { validateObject } from 'utils/validx-validators'
import { PersonalInfoViewModel } from '../PersonalInfoViewModel'

/**
 * View model for managing the Dependent supporting item input.
 */
export class DependentSupportingItemInputViewModel {
  /**
   * The underlying item.
   *
   * @type {RequestedSupportingItem}
   */
  @observable item

  @observable showingDialog = false
  @observable selectedItem = null

  @observable personalInfo = new PersonalInfoViewModel()
  @observable relationship

  validation = validationContext(this, {
    relationship: [required('Relationship is required')],
    personalInfo: [validateObject('Personal information is required')],
  })

  constructor({
    item,
    flashMessageStore,
    individualProfileStore,
    infoGatheringStore,
    getCurrentJob,
  }) {
    /**
     * @type {RequestedSupportingItem}
     */
    this.item = item
    this.flashMessageStore = flashMessageStore
    this.individualProfileStore = individualProfileStore
    this.infoGatheringStore = infoGatheringStore
    this.getCurrentJob = getCurrentJob
  }

  activate = task.resolved(async () => {
    await Promise.all(
      this.item.input.dependents.map((dependentItem) =>
        this.fetchDependentProfile(dependentItem)
      )
    )
  })

  /**
   * The current job.
   */
  @computed
  get currentJob() {
    return this.getCurrentJob()
  }

  /**
   * Dependents added to the job.
   */
  @computed
  get addedDependents() {
    return this.item.input.dependents.map((d) =>
      this.individualProfileStore.getDependentProfileVersion(
        d?.profileId,
        d?.profileVersion
      )
    )
  }

  /**
   * Gets the dependent profile.
   *
   * @param {*} dependentItem
   * @returns
   */
  @task
  async fetchDependentProfile(dependentItem) {
    if (!dependentItem.profileId) {
      return
    }

    await this.individualProfileStore
      .fetchDependentProfile(dependentItem.profileId)
      .catch((ex) => {
        this.flashMessageStore.create({
          type: 'error',
          message: extractMessageFromError(ex),
        })

        throw ex
      })
  }

  /**
   * Submits the dependent information.
   *
   * @returns
   */
  @task.resolved
  async submit() {
    if (!this.validation.reset().validate().isValid) {
      return
    }

    try {
      const result = !this.selectedItem
        ? await this.individualProfileStore.createDependentProfile(
            this.currentJob.workspaceId,
            this.personalInfo.toDto(),
            this.relationship
          )
        : await this.individualProfileStore.updateDependentProfile(
            this.selectedItem.id,
            this.personalInfo.toDto(),
            this.relationship
          )

      await this.infoGatheringStore.provideDependentInfo(
        this.currentJob.id,
        this.item,
        result.id,
        result.version
      )

      this.closeDialog()
    } catch (ex) {
      this.flashMessageStore.create({
        type: 'error',
        message: extractMessageFromError(ex),
      })
    }
  }

  /**
   * Deletes the dependent from the supporting item.
   */
  @task.resolved
  async deleteDependentProfile() {
    try {
      await this.infoGatheringStore.removeDependentInfo(
        this.currentJob.id,
        this.item,
        this.selectedItem.id
      )
      this.closeDialog()
    } catch (ex) {
      this.flashMessageStore.create({
        type: 'error',
        message: extractMessageFromError(ex),
      })
    }
  }

  /**
   * Title for the dependent.
   *
   * @param {*} personalInfo
   * @returns
   */
  title(personalInfo) {
    return [
      personalInfo?.firstName,
      personalInfo?.middleInitial,
      personalInfo?.lastName,
    ]
      .filter(Boolean)
      .join(' ')
  }

  /**
   * Description of the dependents personal info.
   *
   * @param {*} personalInfo
   * @returns
   */
  headerDescription(personalInfo) {
    return [
      personalInfo?.dateOfBirth,
      `${personalInfo?.personalTaxpayerIdentificationNumber}`?.replace(
        /\d{3}-\d{2}-(\d{4})/,
        '✶✶✶-✶✶-$1'
      ),
    ]
      .filter(Boolean)
      .join(' / ')
  }

  /**
   * Shows the dependent information dialog.
   */
  @action
  showDialog(dependentItem) {
    this.selectedItem = dependentItem
    this.fillProfile()
    this.showingDialog = true
  }

  /**
   * Closes the dependent information dialog.
   */
  @action
  closeDialog() {
    this.showingDialog = false
    this.selectedItem = null
  }

  /**
   * Assigns the fetched profile to the view model.
   */
  @action.bound
  fillProfile() {
    this.personalInfo.parse(this.selectedItem?.personalInfo)
    this.relationship = this.selectedItem?.relationship
  }
}
