import { observable, action, computed } from 'mobx'
import { Store } from 'libx'
import { task } from 'mobx-task'
import { validationContext, required } from 'validx'

export default class BookkeepingOtherConnectionDialogStore extends Store {
  @observable
  showing = false

  @observable
  success = false

  @observable
  bookkeepingSoftware = ''

  validation = validationContext(this, {
    bookkeepingSoftware: [required('Bookkeeping software is required')],
  })

  constructor() {
    super(...arguments)
    this.submit = this.submit.bind(this)
  }

  @computed
  get workspaceId() {
    return this.rootStore.sessionStore.workspace.id
  }

  @computed
  get availableBookkeepingSoftwares() {
    return [
      {
        label: 'Freshbooks',
        value: 'FRESHBOOKS',
      },
      {
        label: 'Puzzle',
        value: 'PUZZLE',
      },
      {
        label: 'Sage',
        value: 'SAGE',
      },
      {
        label: 'Wave',
        value: 'WAVE',
      },
      {
        label: 'Xero - Taxfyle Offering',
        value: 'XERO_TAXFYLE_OFFERING',
      },
      {
        label: 'QuickBooks Desktop',
        value: 'QUICKBOOKS_DESKTOP',
      },
      {
        label: 'Other',
        value: 'OTHER',
      },
    ]
  }

  @action
  setBookkeepingSoftware(software) {
    this.bookkeepingSoftware = software.value
  }

  @action
  show() {
    this.showing = true
  }

  @task.resolved
  async submit() {
    if (!this.validation.reset().validate().isValid) {
      return
    }

    const jobId = this.rootStore.projectDetailsStore.project.id
    await this.rootStore.bookkeepingPlatformConnectionStore.providePlatformConnection(
      jobId,
      {
        info: this.bookkeepingSoftware,
        platform: 'Other',
      }
    )

    await this.rootStore.bookkeepingPlatformConnectionStore.markAccessAsGranted(
      jobId
    )

    await this.rootStore.bookkeepingStore.fetchBookkeepingProgressionInfo(jobId)

    this.close()
  }

  @action
  back() {
    this.rootStore.bookkeepingPlatformSelectionStore.show()
    this.close()
  }

  @action
  close() {
    this.showing = false
    this.success = true
    this.bookkeepingSoftware = ''
  }
}
