import { isNil, debounce } from 'lodash'
import { FlashMessageTypes } from '@taxfyle/web-commons/lib/flash-messages/FlashMessageStore'
import { ConfirmDialogState } from 'components/ConfirmDialog'
import ifvisible from 'ifvisible.js'
import MilestonesViewState from 'jobs/MilestonesViewState'
import { Store } from 'libx'
import links from 'misc/links'
import { action, computed, observable, reaction, runInAction } from 'mobx'
import { task } from 'mobx-task'
import { browserHistory } from 'react-router'
import { extractMessageFromError } from 'utils/errorUtil'
import { isMobile } from 'utils/mobileDetect'
import { downloadPdf } from '@taxfyle/web-commons/lib/utils/pdfUtil'
import moment from 'moment'
import SelectBillingMethodState from '../../screens/ProjectDetails/SelectBillingMethodDialog/SelectBillingMethodState'
import { getFeatureToggleClient } from 'misc/featureToggles'
import { InfoGatheringState } from '../../screens/ProjectDetails/InfoGatheringStage/InfoGatheringState'
import RateProDialogViewModel from '../../screens/ProjectDetails/Ratings/RateProDialog/RateProDialogViewModel'
import RatePlatformDialogViewModel from '../../screens/ProjectDetails/Ratings/RatePlatformDialog/RatePlatformDialogViewModel'
import InfiniteScrollProvider from '@taxfyle/web-commons/lib/data/InfiniteScrollProvider'
import SelectScheduleDialogViewModel from '../../screens/ProjectDetails/Schedule/SelectScheduleDialog/SelectScheduleDialogViewModel'
import RoomViewModel from 'src/client/screens/ProjectDetails/Rooms/RoomViewModel'
import { filter, switchMap, tap, startWith } from 'rxjs/operators'
import RequestedDocumentsDialogStore from 'jobs/RequestedDocumentDialogStore'
import isDocumentRequestFlowEnabled from 'src/client/infra/documentRequestFlowToggle'
import QS from 'qs'
import { toStream } from 'utils/mobxUtil'
import { interval, NEVER } from 'rxjs'
import { filterNonNil, withCancellation } from 'utils/rxUtil'
import { defaultCancellationToken } from '@taxfyle/web-commons/lib/utils/cancellation'

export default class ProjectDetailsStore extends Store {
  @observable
  project = null

  @observable
  name = ''

  @observable
  composerText = ''

  @observable
  notFound = true

  @observable
  detailsTab = 'MESSAGES'

  @observable
  milestonesViewState = null

  @observable
  eventCategory = 'ALL'

  @observable
  questions = []

  @observable
  hasDiyForCurrentYear = false

  @observable
  qs

  @observable
  userData = undefined

  jobEventsScroller = new InfiniteScrollProvider({
    limit: 100,
    memoizeInitial: true,
    fetchItems: (params) =>
      this.rootStore.eventStore.fetchJobEvents(
        this.project.id,
        params.limit,
        params.after
      ),
  })

  constructor() {
    super(...arguments)
    this.linkDocument = this.linkDocument.bind(this)
    this.onIncomingMessage = this.onIncomingMessage.bind(this)
    this.saveName = this.saveName.bind(this)
    this.sendMessage = this.sendMessage.bind(this)
    this.downloadConversation = this.downloadConversation.bind(this)
    this.deleteMessage = this.deleteMessage.bind(this)
    this.loadMessages = this.loadMessages.bind(this)
    this.loadScopeChangeRequests = this.loadScopeChangeRequests.bind(this)
    this.loadEvents = this.loadEvents.bind(this)
    this.loadDocuments = this.loadDocuments.bind(this)
    this.fetchProjectTeamMember = this.fetchProjectTeamMember.bind(this)
    this.ensureTermsAcceptance = this.ensureTermsAcceptance.bind(this)
    this.linkDocument = this.linkDocument.bind(this)
    this.holdJob = this.holdJob.bind(this)
    this.rootStore.messageStore.onIncomingMessage(this.onIncomingMessage)
    this.copyJobDialog = new ConfirmDialogState()
    this.promptDialogStore = this.rootStore.promptDialogStore
    this.scopeChangeRequestResponseDialogStore =
      this.rootStore.scopeChangeRequestResponseDialogStore
    this.scopeChangeRequestResponseConfirmationDialogStore =
      this.rootStore.scopeChangeRequestResponseConfirmationDialogStore
    this.approveDraftDialogStore = this.rootStore.approveDraftDialogStore
    this.rejectDraftDialogStore = this.rootStore.rejectDraftDialogStore
    this.promptStore = this.rootStore.promptStore
    this.jobTaxAssistantStore = this.rootStore.jobTaxAssistantStore
    this.selectBillingMethodState = new SelectBillingMethodState(
      this.rootStore.billingStore
    )

    this.scheduleStore = this.rootStore.scheduleStore
    this.roomStore = this.rootStore.roomStore
    this.confirmPhoneDialogStore = this.rootStore.confirmPhoneDialogStore
    this.confirmScheduleDialogStore = this.rootStore.confirmScheduleDialogStore
    this.termsDialogVM = this.rootStore.termsDialogVM
    this.handoffDialogVM = this.rootStore.handoffDialogVM
    this.rateProDialogViewModel = new RateProDialogViewModel({
      projectDetailsStore: this,
      jobRatingStore: this.rootStore.jobRatingStore,
      flashMessageStore: this.rootStore.flashMessageStore,
    })
    this.ratePlatformDialogViewModel = new RatePlatformDialogViewModel({
      projectDetailsStore: this,
      sessionStore: this.rootStore.sessionStore,
      jobRatingStore: this.rootStore.jobRatingStore,
      flashMessageStore: this.rootStore.flashMessageStore,
    })
    this.infoGatheringState = new InfoGatheringState({
      jobStore: this.rootStore.jobStore,
      infoGatheringStore: this.rootStore.infoGatheringStore,
      individualProfileStore: this.rootStore.individualProfileStore,
      taxRefundMethodStore: this.rootStore.taxRefundMethodStore,
      documentStore: this.rootStore.documentStore,
      documentTypeStore: this.rootStore.documentTypeStore,
      documentVersionStore: this.rootStore.documentVersionStore,
      documentAccessStore: this.rootStore.documentAccessStore,
      flashMessageStore: this.rootStore.flashMessageStore,
      sessionStore: this.rootStore.sessionStore,
      projectDetailsStore: this,
      jobTaxAssistantGreetingDialogStore:
        this.rootStore.jobTaxAssistantGreetingDialogStore,
      businessProfileStore: this.rootStore.businessProfileStore,
      draftStore: this.rootStore.draftStore,
      projectStore: this.rootStore.projectStore,
    })
    this.selectScheduleDialogViewModel = new SelectScheduleDialogViewModel({
      scheduleStore: this.rootStore.scheduleStore,
      confirmScheduleDialog: this.confirmScheduleDialogStore,
    })
    this.roomVM = new RoomViewModel({
      projectDetailsStore: this,
      roomStore: this.rootStore.roomStore,
    })

    this.requestedDocumentsDialogStore = new RequestedDocumentsDialogStore({
      rootStore: this.rootStore,
    })

    reaction(
      () => this.project && this.project.name,
      (name) => name && this.setName(name)
    )
    // Whenever the answers change (server update), fetch questions
    reaction(
      () => this.project && this.project.answers,
      () => !this.fetchQuestions.pending && this.fetchQuestions()
    )
    // Whenever the job status changes, fetch scope changes
    reaction(
      () => this.project && this.project.status,
      () => this.project && this.loadScopeChangeRequests()
    )

    // If the status changes fetch the job with the current consultation
    reaction(
      () => this.project && this.project.status,
      () =>
        this.project?.consultation &&
        this.rootStore.projectStore.fetch(this.project.id)
    )

    // If the status changes, fetch booked time slots.
    reaction(
      () => this.project && this.project.status,
      () =>
        this.scheduleStore.fetchBookedTimeRangesForPro(defaultCancellationToken)
    )

    this.sendJobTaxAssistantPrompt = debounce(
      this.sendJobTaxAssistantPrompt.bind(this),
      500
    )

    toStream(() => this.detailsTab)
      .pipe(
        switchMap((tab) =>
          tab !== 'SCHEDULE'
            ? NEVER
            : interval(1000 * 30).pipe(
                startWith(0),
                filter(() => this.scheduleStore.shouldRefetch()),
                switchMap(() =>
                  withCancellation((ct) =>
                    this.scheduleStore.fetchBookedTimeRangesForPro(ct)
                  )
                )
              )
        )
      )
      .subscribe()

    toStream(() => this.project, true)
      .pipe(
        filterNonNil(),
        switchMap((job) =>
          this.roomStore.events$.pipe(
            filter((e) => e.type !== 'Unknown'),
            filter((e) => e.room.conversation.id === job.conversation?.id),
            filter((e) => e.room.id !== job.consultation?.room.id),
            tap(() => this.rootStore.projectStore.fetch(job.id))
          )
        )
      )
      .subscribe()

    // If we're on the checklist tab and something changes that hides the
    // checklist, re-activate the details tab.
    toStream(() => this.infoGatheringState.hasChecklist)
      .pipe(
        filter(
          (isChecklistTabAvailable) =>
            !isChecklistTabAvailable && this.detailsTab === 'CHECKLIST'
        ),
        tap(() => this.activateDetailsTab())
      )
      .subscribe()
  }

  get isMobile() {
    return isMobile()
  }

  /**
   * Determinate if the current member is a firm admin in the given workspace
   * by checking the user email, user type and workspace type.
   *
   * @returns {boolean}
   */
  @computed
  get isFirmAdmin() {
    const member = this.rootStore.sessionStore.member
    if (!this.userData || !member) {
      return false
    }

    const invalidUserEmailDomains = ['@taxfyle.com', '@worklayer.com']
    return Boolean(
      this.userData?.userDataType === 'USER' &&
        member.workspace?.classification === 'B2B' &&
        !invalidUserEmailDomains.some((domain) => member.email.includes(domain))
    )
  }

  @computed
  get isProvider() {
    return this.project?.providers?.some(
      (x) => x.user.userPublicId === this.rootStore.sessionStore.user.public_id
    )
  }

  @computed
  get loadingMessages() {
    return this.loadMessages.pending
  }

  @computed
  get conversation() {
    if (!this.project) {
      return null
    }

    return this.rootStore.messageStore.conversations.get(
      this.project.conversationId
    )
  }

  @computed
  get messages() {
    if (!this.project) {
      return []
    }

    return this.rootStore.messageStore.messagesIn(this.project.conversationId)
  }

  @computed
  get prompts() {
    if (!this.project) {
      return []
    }

    return this.promptStore.forJob(this.project.id)
  }

  @computed
  get documents() {
    if (this.project) {
      return this.rootStore.documentStore.forJob(this.project.id)
    }

    return []
  }

  @computed
  get scopeChangeRequests() {
    if (
      !this.project ||
      !this.rootStore.scopeChangeRequestStore.scopeChangeRequests
    ) {
      return []
    }

    const promptClientApproval =
      this.rootStore.scopeChangeRequestStore.isPromptClientApprovalEnabled()
    const scopeChangeRequests =
      this.rootStore.scopeChangeRequestStore.forJobSortedByDate(this.project.id)

    return promptClientApproval
      ? scopeChangeRequests.filter(
          (scopeChangeRequest) => scopeChangeRequest.isAdminApproved
        )
      : scopeChangeRequests.filter(
          (scopeChangeRequest) => scopeChangeRequest.isApproved
        )
  }

  @computed
  get shouldHideScopeChangeRequests() {
    return this.scopeChangeRequests.length === 0
  }

  @computed
  get unreadMessagesCount() {
    return this.messages.filter((x) => x.isRead === false).length
  }

  @computed
  get showingComposer() {
    if (!this.project || !this.project.conversationId) {
      return false
    }

    if (this.rootStore.authStore.user.impersonator) {
      return false // Impersonators not allowed to chat on behalf of user.
    }

    if (this.permissionSubject.hasPermission('CHAT_ADD') === false) {
      return false
    }

    if (this.project.conversation && this.project.conversation.accessDenied) {
      return false
    }

    if (
      this.loadMessages.rejected ||
      this.loadMessages.result !== 'Conversation'
    ) {
      return false
    }

    if (this.infoGatheringState.disableChatForFirmAdmin) {
      return false
    }

    return !this.loadingMessages
  }

  /**
   * Gets a scroll provider for the current job's documents.
   */
  @computed
  get documentsScrollProvider() {
    return this.rootStore.documentStore.getScrollProvider(
      this.project.id,
      (params) =>
        this.rootStore.documentStore.fetchDocumentsForJob({
          jobId: this.project.id,
          ...params,
        })
    )
  }

  @computed
  get promptsScrollProvider() {
    return this.promptStore.getScrollProvider(
      this.project.id,
      (params) => this.fetchPrompts(params),
      10
    )
  }

  @computed
  get permissionSubject() {
    const member = this.rootStore.sessionStore.member
    if (this.project && this.project.ownerTeamId) {
      const teamMember = this.rootStore.teamMemberStore.forTeamAndUser(
        this.project.ownerTeamId,
        member.userId
      )

      return teamMember || member
    }
    return member
  }

  @computed
  get incompleteTasksCount() {
    const nextMilestone =
      this.milestonesViewState && this.milestonesViewState.nextMilestone
    if (!nextMilestone) {
      return 0
    }
    return nextMilestone.tasks.filter(
      (x) => x.designee === 'Client' && !x.completed
    ).length
  }

  @computed
  get stripePurchaseTransactions() {
    if (!this.project) {
      return []
    }

    return this.rootStore.billingStore
      .transactionsForJob(this.project.transactions)
      .filter(
        (x) => x && x.transactionType === 'PURCHASE' && x.metadata.receipt_url
      )
  }

  @computed
  get customTermsOfUseEnabled() {
    return this.rootStore.sessionStore.workspace.features.tos.customTermsEnabled
  }

  @computed
  get shouldPromptForTerms() {
    const tos = this.rootStore.sessionStore.workspace.features.tos
    const newTermsEnabled = getFeatureToggleClient().variation(
      'Portals.UseNewTerms',
      false
    )
    return newTermsEnabled ? !tos.hideOnCustomerPortal : tos.enabled
  }

  @computed
  get tabConfig() {
    return this.rootStore.sessionStore.workspace.features.jobDetailsConfig
      .tabConfig
  }

  @computed
  get detailsTabEnabled() {
    return (
      !this.tabConfig.enabled ||
      (this.tabConfig.enabled && this.tabConfig.details)
    )
  }

  @computed
  get eventsTabEnabled() {
    return (
      !this.tabConfig.enabled ||
      (this.tabConfig.enabled && this.tabConfig.events)
    )
  }

  @computed
  get membersTabEnabled() {
    return (
      !this.tabConfig.enabled ||
      (this.tabConfig.enabled && this.tabConfig.members)
    )
  }

  /**
   * Indicates whether the scheduled tab is enabled.
   * @returns {boolean}
   */
  @computed
  get scheduleTabEnabled() {
    // Skip scheduling consultations will always have the tab enabled so that
    // clients can see the copy asking them to message the pro.
    if (
      this.project?.consultation?.skipScheduling &&
      this.isActiveConsultation
    ) {
      return true
    }

    // Otherwise, the consultation must be active, and for tax jobs, the job
    // can't be in info gathering.
    return this.project?.jobType?.tax
      ? this.isActiveConsultation && !this.infoGatheringState.inInfoGathering
      : this.isActiveConsultation
  }

  @computed
  get isBookkeepingEnabled() {
    return Boolean(
      getFeatureToggleClient().variation('Portals.BookkeepingEnabled', false) &&
        this.bookkeepingProgressionInfo
    )
  }

  @computed
  get bookkeepingProgressionInfo() {
    return this.rootStore.bookkeepingStore.bookkeepingProgressions.get(
      this.project.id
    )
  }

  @computed
  get jobProgressionFlowEnabled() {
    if (this.project?.jobType?.tax === undefined) {
      return false
    }

    const workspaceConfig = this.rootStore.sessionStore.workspace.config

    const newJobProgressionConfig = getFeatureToggleClient().variation(
      'HQ.UseUpdatedJobProgressionConfig',
      false
    )
    const jobProgressionFlowEnabled = newJobProgressionConfig
      ? workspaceConfig.job_progression_config &&
        workspaceConfig.job_progression_config.enabled &&
        workspaceConfig.job_progression_config.legends[this.project.legendId] &&
        workspaceConfig.job_progression_config.legends[
          this.project.legendId
        ] !== 'NONE'
      : workspaceConfig.job_config &&
        workspaceConfig.job_config.job_progression_flow

    return jobProgressionFlowEnabled
  }

  @computed
  get jobProgression() {
    if (!this.jobProgressionFlowEnabled) {
      return
    }

    return this.rootStore.jobProgressionStore.progressions.find(
      (p) => p.jobId === this.project.id
    )
  }

  @computed
  get isOutsourcingExtensionFlow() {
    return this.jobProgression?.flow === 'OUTSOURCING_EXTENSION'
  }

  @computed
  get isWaitingOnClient() {
    const currentStep = this.jobProgression?.progressionSteps?.find((p) =>
      isNil(p.response)
    )

    return currentStep?.status === 'WAITING_ON_CLIENT'
  }

  @computed
  get isInDraftReview() {
    const currentStep = this.jobProgression?.progressionSteps?.find((p) =>
      isNil(p.response)
    )

    return currentStep?.status === 'DRAFT_IN_REVIEW'
  }

  @computed
  get isInDraftApproved() {
    const currentStep = this.jobProgression?.progressionSteps?.find((p) =>
      isNil(p.response)
    )

    return currentStep?.status === 'DRAFT_APPROVED'
  }

  @computed
  get isInOrPastDraftApproved() {
    const currentStep = this.jobProgression?.progressionSteps?.find(
      (p) => p.status === 'DRAFT_APPROVED'
    )

    return !isNil(currentStep)
  }

  @computed
  get isConsultation() {
    return Boolean(this.project.consultation)
  }

  @computed
  get consultationLength() {
    if (this.isConsultation) {
      return this.project.consultation?.requestedLength || 60
    }
    return 0
  }

  @computed
  get isConsultationPendingAvailability() {
    return this.project.consultation?.status === 'PENDING_AVAILABILITY'
  }

  @computed
  get isConsultationPendingBooking() {
    return this.project.consultation?.status === 'PENDING_BOOKING'
  }

  @computed
  get isConsultationAvailabilityElapsed() {
    return (
      this.isConsultationPendingAvailability &&
      this.project.consultation?.availability
    )
  }

  @computed
  get isConsultationBooked() {
    return this.project.consultation?.bookedTime
  }

  @computed
  get isConsultationBookedAndActive() {
    return (
      this.isConsultationBooked &&
      (this.project.consultation?.status === 'PENDING_CALL' ||
        this.project.consultation?.status === 'IN_PROGRESS')
    )
  }

  @computed
  get isConsultationCompleted() {
    return this.isConsultation && this.project.consultation?.status === 'DONE'
  }

  @computed
  get isActiveConsultation() {
    return (
      this.isConsultation &&
      this.project.consultation?.status !== 'DONE' &&
      this.project.consultation?.status !== 'CANCELED'
    )
  }

  @computed
  get showConsultationSummary() {
    return (
      !this.project.provider &&
      !this.project.consultation?.skipScheduling &&
      this.isConsultationPendingBooking
    )
  }

  @computed
  get allowConsultationReschedule() {
    const consultation = this.project.consultation || {}
    if (consultation.skipScheduling) {
      return false
    }

    if (
      this.isConsultationPendingAvailability ||
      this.isConsultationPendingBooking
    ) {
      return true
    }

    const now = moment()
    const bookedDate = moment(consultation.bookedTime?.startTime)
    const hourDiff = bookedDate.diff(now, 'hours')

    return hourDiff >= 12 && consultation.status === 'PENDING_CALL'
  }

  @computed
  get isInOpenItems() {
    const currentStep = this.jobProgression?.progressionSteps?.find((p) =>
      isNil(p.response)
    )

    return (
      isDocumentRequestFlowEnabled() && currentStep?.status === 'OPEN_ITEMS'
    )
  }

  @computed
  get hasPendingDocuments() {
    return this.requestedDocumentsDialogStore.hasPendingDocuments
  }

  @computed
  get jobTaxAssistant() {
    return this.jobTaxAssistantStore.forJob(this.project?.id)
  }

  @computed
  get isJobTaxAssistantEnabled() {
    // If we get an assistant by other means then it is enabled:
    // - Ws config was on then off but assistant got created
    // - RT events
    // - Loading an instance
    if (this.jobTaxAssistant) {
      return true
    }

    return (
      getFeatureToggleClient().variation('Services.LuisAI', false) &&
      !isNil(this.project?.jobType?.tax) &&
      isNil(this.project?.provider) &&
      this.rootStore.sessionStore.workspace.config?.message_config
        ?.tax_assistant?.enabled
    )
  }

  @computed
  get jobTaxAssistantPrompts() {
    return !this.project.completed && this.jobTaxAssistant?.active
      ? this.rootStore.jobTaxAssistantStore.getPrompts()
      : undefined
  }

  @action.bound
  updateJob() {
    this.rootStore.updateJobDialogStore.init(this.project)
    this.rootStore.updateJobDialogStore.show()
  }

  @action.bound
  updateDeadline() {
    this.rootStore.updateDeadlineDialogStore.show(this.project)
  }

  @action.bound
  viewJobProgressionTimeline() {
    this.rootStore.jobProgressionTimelineDialogStore.show()
  }

  @action.bound
  viewJobTaxAssistantInfoDialog() {
    this.rootStore.jobTaxAssistantInfoDialogStore.show()
  }

  @action.bound
  changeComposerText(newValue) {
    this.composerText = newValue
  }

  @action.bound
  changeEventCategory(value) {
    this.eventCategory = value
  }

  @action.bound
  viewPrompt(prompt) {
    this.promptDialogStore.show(prompt)
  }

  @action.bound
  viewRejectDraftDialog(jobProgression) {
    this.rejectDraftDialogStore.show(jobProgression)
  }

  @action.bound
  viewApproveDraftDialog(jobProgression) {
    this.approveDraftDialogStore.show(jobProgression)
  }

  @action.bound
  viewRequestedDocuments() {
    this.requestedDocumentsDialogStore.show()
  }

  @action.bound
  activate(projectId, preventDraftRedirect = false) {
    runInAction(() => {
      this.qs = QS.parse(location.search, { ignoreQueryPrefix: true })
    })

    const breakIfUnderConstruction = (p) => {
      if (p.status === 'UNDER_CONSTRUCTION' && !preventDraftRedirect) {
        browserHistory.replace(links.projectEditor(p.id, this.qs?.hideNavbar))
        return true
      }

      return false
    }

    this.infoGatheringState.reset()
    this.rateProDialogViewModel.deactivate()
    this.ratePlatformDialogViewModel.deactivate()
    this.project = null
    this.notFound = false
    this.composerText = ''

    const projectStore = this.rootStore.projectStore
    return projectStore
      .fetchProject(projectId)
      .then(
        action((project) => {
          if (project) {
            if (breakIfUnderConstruction(project)) {
              return
            }
          }
          this.project = project
          this.notFound = !project

          if (project) {
            this.rootStore.createBookkeepingPlatformConnectionStore
              .activate(this.project)
              .then()

            const diyJobId = this.project.jobType.consultation?.diyJobId?.value
            if (diyJobId) {
              const taxYearCheck = this.rootStore.api.diy
                .checkTaxYearForDiyJob({
                  diyJobId: diyJobId,
                })
                .then()

              if (taxYearCheck.currentTaxYear !== taxYearCheck.diyJobTaxYear) {
                this.hasDiyForCurrentYear = false
              } else {
                this.hasDiyForCurrentYear = true
              }
            }
            return this._activate()
          }
        })
      )
      .catch((err) => {
        this.rootStore.flashMessageStore
          .create(extractMessageFromError(err))
          .failed()
        runInAction(() => {
          browserHistory.replace(links.projects)
        })
      })
  }

  async fetchUserData() {
    const member = this.rootStore.sessionStore.member
    const userData = await this.rootStore.userDataStore.getByPublicId(
      member.userPublicId
    )
    runInAction(() => {
      this.userData = userData
    })
  }

  async fetchJobTaxAssistant() {
    await this.jobTaxAssistantStore.getJobTaxAssistant(this.project?.id)
  }

  @task
  async _activate() {
    await this.fetchProjectTeamMember()
    const sessionStore = this.rootStore.sessionStore
    if (this.project.workspaceId !== sessionStore.workspace.id) {
      await sessionStore.selectWorkspace(this.project.workspaceId)
    }

    await this.termsDialogVM.activate()
    await this.fetchJobTaxAssistant()
    await this.infoGatheringState.activate(this.project)
    this.rateProDialogViewModel.activate()
    this.ratePlatformDialogViewModel.activate()
    this.activateDetailsTab()

    if (
      this.project.milestones.length > 0 &&
      this.project.hasClientTasks &&
      !this.isMobile &&
      !this.jobProgressionFlowEnabled
    ) {
      this.changeDetailsTab('MILESTONES')
    }

    this.createMilestonesViewState()
    this.setName(this.project.name || this.project.description)

    return Promise.all([
      this.loadTransactions(),
      this.loadScopeChangeRequests(),
      this.loadMessages(),
      this.loadDocuments(),
      this.loadEvents(),
      this.loadDetails(),
      this.ensureTermsAcceptance(),
      this.activateConsultationViewState(),
      this.fetchUserData(),
    ])
  }

  goToDiy = task.resolved(async () => {
    if (!this.hasDiyForCurrentYear) {
      return
    }

    runInAction(async () => {
      if (window.localStorage.getItem('accessOrigin') === 'mobile') {
        const workspaceId = this.rootStore.sessionStore.workspace.id
        await this.rootStore.api.diy
          .createDiyUrl({ workspaceId })
          .then((res) => {
            window.location.href = res.url
          })
      } else {
        browserHistory.push(links.diy({ hideNavbar: this.qs?.hideNavbar }))
      }
    })
  })

  @task
  async loadDetails() {
    return Promise.all([
      this.fetchQuestions(),
      this.promptsScrollProvider.fetch(),
      this.fetchJobProgression(),
      this.fetchBookkeepingProgressionInfo(),
      this.fetchTicketsForJob(),
    ])
  }

  /**
   * Indicates whether the active details tab can be automatically set to be the
   * schedule tab.
   * @returns {boolean|boolean}
   */
  @computed
  get canSetDetailsTabToSchedule() {
    // Skip scheduling consultations will always show the copy asking the client
    // to message the pro to schedule a consultation.
    if (this.project?.consultation?.skipScheduling) {
      return true
    }

    // Otherwise, tax jobs will only allow the tab to be shown if the job isn't
    // in info gathering.
    return this.project?.jobType?.tax
      ? !this.infoGatheringState.inInfoGathering
      : true
  }

  /**
   * Indicates whether the active details tab can be automatically set to be the
   * checklist tab.
   * @returns {boolean|boolean}
   */
  @computed
  get canSetDetailsTabToChecklist() {
    return (
      this.infoGatheringState.hasChecklist &&
      // If the consultation is pending availability,
      // we don't want to show the checklist tab.
      !(
        this.isConsultationPendingAvailability &&
        this.canSetDetailsTabToSchedule
      )
    )
  }

  @action
  activateDetailsTab() {
    if (
      this.isMobile &&
      this.isConsultationPendingAvailability &&
      this.canSetDetailsTabToSchedule
    ) {
      this.detailsTab = 'SCHEDULE'
    } else if (this.isMobile) {
      this.detailsTab = this.infoGatheringState.allRequiredItemsCompleted
        ? 'MESSAGES'
        : 'CHECKLIST'
    } else if (this.canSetDetailsTabToChecklist) {
      this.detailsTab = 'CHECKLIST'
    } else if (this.isConsultation && this.canSetDetailsTabToSchedule) {
      this.detailsTab = 'SCHEDULE'
    } else {
      this.detailsTab = this.detailsTabEnabled ? 'DETAILS' : 'DOCUMENTS'
    }
  }

  @action.bound
  showConfirmPhoneDialog = async () => await this.confirmPhoneDialogStore.show()

  @action.bound
  showConfirmScheduleDialog = async () =>
    await this.confirmScheduleDialogStore.show()

  updateAvailability = task.resolved(async () => {
    if (!this.showConsultationSummary || this.allowConsultationReschedule) {
      await this.scheduleStore.fetchBookedTimeRangesForPro(
        defaultCancellationToken,
        true
      )

      this.selectScheduleDialogViewModel.show()
    }
  })

  answerReadyToFile = task.resolved(async () => {
    await this.rootStore.jobProgressionStore.setReadyToFileProgression(
      this.project.id
    )
  })

  downloadConsultationCalendar = task.resolved(async () => {
    try {
      if (!this.isConsultationBookedAndActive) {
        return
      }

      const file =
        await this.rootStore.api.consultations.downloadConsultationCalendar({
          jobId: this.project.id,
        })
      const objectUrl = URL.createObjectURL(file)
      const a = document.createElement('a')
      a.href = objectUrl
      a.download = file.name
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      window.URL.revokeObjectURL(objectUrl)
    } catch (e) {
      console.error('Calendar download failed', e)
    }
  })

  @action.bound
  createMilestonesViewState() {
    this.milestonesViewState = new MilestonesViewState({
      ...this.rootStore,
      job: this.project,
    })
  }

  @task.resolved
  async activateConsultationViewState() {
    await this.scheduleStore.activate(this.project.consultation)
  }

  @action.bound
  setName(name) {
    this.name = name
  }

  @action.bound
  changeDetailsTab(newTab) {
    this.detailsTab = newTab
  }

  @action.bound
  stageFiles(files) {
    const jobId = this.project.id
    return this.rootStore.documentStore.newDocument(files, {
      access: {
        type: 'Job',
        job_id: jobId,
      },
    })
  }

  @action.bound
  copyJob() {
    this.copyJobDialog.show().then(async (yes) => {
      if (yes) {
        const msg = this.rootStore.flashMessageStore.create({
          message: 'Copying job...',
          inProgress: true,
        })
        try {
          await this.rootStore.projectStore.copyJob(this.project.id)
          msg.done('Job successfully copied').autoDismiss()
        } catch (e) {
          msg.failed(extractMessageFromError(e)).autoDismiss()
        }
      }
    })
  }

  @task.resolved
  holdJob(hold) {
    if (!this.project) {
      return
    }
    return this.rootStore.projectStore
      .holdJob(this.project.id, hold)
      .then(() => {
        this.rootStore.flashMessageStore
          .create(hold ? 'Job put on hold.' : 'Job resumed.')
          .done()
          .autoDismiss(4000)
      })
      .catch((err) => {
        this.rootStore.flashMessageStore
          .create(extractMessageFromError(err))
          .failed()
      })
  }

  @task
  async setConsultationAvailability(availability) {
    if (!this.project) {
      return
    }

    await this.rootStore.projectStore.setConsultationAvailability(
      this.project.id,
      availability
    )
  }

  @task
  async fetchJobProgression() {
    if (this.jobProgressionFlowEnabled) {
      await this.rootStore.jobProgressionStore.fetchProgression(this.project.id)
    }
  }

  @task
  async fetchBookkeepingProgressionInfo() {
    await this.rootStore.bookkeepingStore.fetchBookkeepingProgressionInfo(
      this.project.id
    )
  }

  @task
  async fetchTicketsForJob() {
    return await this.rootStore.jobTicketStore.fetchLatestOpenTicketsForJobs([
      this.project.id,
    ])
  }

  @task
  async fetchJobRatingStatus() {
    const response = await this.rootStore.jobRatingStore.getJobRatingStatus(
      this.project.id
    )

    this.rateProDialogViewModel.setProRatingStatus(response.proRating)
    this.ratePlatformDialogViewModel.setPlatformRatingStatus(
      response.platformRating
    )
  }

  /**
   * Triggered when a new message comes in from the wire.
   * We need to figure out what to do with it based on
   * what project the user is currently viewing.
   *
   * @param message
   */
  onIncomingMessage(message) {
    const conversation = this.rootStore.messageStore.conversations.get(
      this.project && this.project.conversationId
    )
    if (conversation) {
      if (!conversation.muted) {
        this.rootStore.soundStore.playGotMessage()
      }
      if (conversation.id === message.conversationId && ifvisible.now()) {
        // Don't notify with desktop notifications when we're already viewing the
        // messages for the conversation the message belongs to.
        return
      }
    }

    if (message.sender) {
      this.rootStore.desktopNotificationStore.show({
        title: `New message from ${message.sender.displayName}`,
        icon: message.sender.avatar,
        body: message.text,
        onClick: async () => {
          const project = this.rootStore.projectStore.forConversation(
            message.conversationId
          )
          if (project) {
            browserHistory.push(links.projectDetails(project.id))
          }
        },
      })
    }
  }

  @task.resolved
  async saveName() {
    try {
      await this.rootStore.projectStore.rename(
        this.project.id,
        this.name.trim()
      )
    } finally {
      this.setName(this.project.name)
    }
  }

  async downloadConversation() {
    const msg = this.rootStore.flashMessageStore.create({
      message: 'Downloading conversation ...',
      type: FlashMessageTypes.DEFAULT,
      inProgress: true,
    })
    try {
      let hasMore = this.conversation.hasMoreMessages
      while (hasMore) {
        await this.rootStore.messageStore.loadMore(
          this.project.conversationId,
          this.project.ownerTeamId
        )
        hasMore = this.conversation.hasMoreMessages
      }

      const result = [
        {
          type: 'title',
          text: `Conversation History for ${this.project.name}`,
        },
      ]
      result.push(
        ...this.rootStore.messageStore
          .messagesIn(this.project.conversationId)
          .map((m) => ({
            type: 'text',
            text: `${m.sentAt.format('MMM DD, YYYY, h:mm A')} - ${
              m.sender.displayName
            }: ${m.text}`,
          }))
      )

      await downloadPdf(
        result,
        `Conversation-${this.project.name}-${moment().format(
          'MMM DD, YYYY, h:mm A'
        )}`
      )

      msg.done('Done.').autoDismiss()
    } catch (err) {
      msg.failed(extractMessageFromError(err))
    }
  }

  async sendMessage(modeNote) {
    if (!this.composerText || !this.composerText.trim()) {
      return
    }

    if (modeNote) {
      await this.rootStore.messageStore.sendNote({
        conversationId: this.project.conversationId,
        text: this.composerText,
        teamId: this.project.ownerTeamId,
      })
    } else {
      await this.rootStore.messageStore.sendMessage({
        conversationId: this.project.conversationId,
        text: this.composerText,
      })
    }

    runInAction(() => {
      this.composerText = ''
    })
  }

  async sendJobTaxAssistantPrompt(prompt) {
    if (!prompt) {
      return
    }

    await this.rootStore.messageStore.sendMessage({
      conversationId: this.project.conversationId,
      text: prompt,
    })
  }

  async deleteMessage(id) {
    if (!(await this.rootStore.deleteMessageConfirm.show())) {
      return
    }
    await this.rootStore.messageStore.deleteMessage(id)
  }

  @task
  async loadTransactions() {
    const billingStore = this.rootStore.billingStore

    await billingStore.fetchTransactions(this.project.transactions)
  }

  @task
  async loadScopeChangeRequests() {
    if (
      !this.rootStore.sessionStore.workspace.config?.combined_pricing
        ?.job_scope_change_requests_enabled
    ) {
      return
    }

    await this.rootStore.scopeChangeRequestStore.fetchScopeChangeRequestsForJob(
      this.project.id
    )
  }

  /**
   * Loads messages and returns a string identifier used by the view to determine what to render.
   */
  @task
  async loadMessages() {
    if (this.permissionSubject.hasPermission('CHAT_VIEW') === false) {
      return 'Denied'
    }

    if (!this.project.conversationId) {
      return 'Conversation'
    }

    const messageStore = this.rootStore.messageStore

    await messageStore
      .fetchMessagesForConversation(
        this.project.conversationId,
        !this.isProvider && this.project.ownerTeamId
      )
      .catch((err) => {
        if (err.errorType === 'NOT_IN_CONVO') {
          return null
        }

        throw err
      })

    if (this.project.conversation.accessDenied) {
      return 'NotInConvo'
    }

    await messageStore.getMyParticipant(this.project.conversationId)

    return 'Conversation'
  }

  @task
  loadEvents(limit, after) {
    return this.rootStore.eventStore.fetchJobEvents(
      this.project.id,
      limit,
      after
    )
  }

  /**
   * Loads documents and returns a string identifier telling the view what to render.
   */
  @task
  async loadDocuments(params) {
    const member = this.permissionSubject
    if (!member.hasPermission('DOCS_VIEW')) {
      return 'Denied'
    }
    await this.documentsScrollProvider.fetch(params)
    return 'List'
  }

  @task
  async fetchProjectTeamMember() {
    if (!this.project.ownerTeamId) {
      return
    }
    await this.rootStore.teamMemberStore.fetchUserTeamMembersByPublicId({
      team_id: this.project.ownerTeamId,
      user_id: this.rootStore.sessionStore.user.public_id,
    })
  }

  @task
  async fetchQuestions() {
    if (!this.project) {
      return
    }

    await this.rootStore.questionStore.fetchQuestions(this.project.id)

    runInAction(() => {
      this.questions = this.rootStore.questionStore.questionsForJob(
        this.project.id
      )
    })
  }

  @task
  async fetchPrompts(params) {
    if (!this.project) {
      return { data: [] }
    }

    return this.promptStore
      .fetchPromptsForJob({
        jobId: this.project.id,
        ...params,
      })
      .catch(() => {
        // Uncomment to flash error.
        // this.rootStore.flashMessageStore
        //   .create(extractMessageFromError(err))
        //   .failed()

        // Fail silently.
        return { data: [] }
      })
  }

  @task
  async fetchScopeChangeRequests() {
    if (!this.project) {
      return { data: [] }
    }

    return this.rootScope.scopeChangeRequestStore
      .fetchScopeChangeRequestsForJob(this.project.id)
      .catch(() => {
        return { data: [] }
      })
  }

  @task
  async ensureTermsAcceptance() {
    // Impersonators should not trigger terms acceptance
    if (
      !this.shouldPromptForTerms ||
      this.rootStore.authStore.user.impersonator
    ) {
      return
    }

    // If custom terms is not enabled for the workspace accept global terms.
    const workspaceId = this.customTermsOfUseEnabled
      ? this.rootStore.sessionStore.workspace.id
      : null

    let latestTermsOfUseAccepted =
      await this.rootStore.memberStore.checkTermsOfUseAcceptance(workspaceId)
    if (!latestTermsOfUseAccepted) {
      this.handoffDialogVM.tryToShowDialog()
      latestTermsOfUseAccepted = await this.termsDialogVM.ensureAccepted()
    }

    if (!latestTermsOfUseAccepted) {
      browserHistory.push(links.projects())
    }
  }

  @task.resolved
  async linkDocument(document) {
    return this.rootStore.documentAccessStore.createAccess(document.id, {
      access: {
        type: 'Job',
        job_id: this.project.id,
      },
    })
  }

  @action.bound
  async toggleMute() {
    const conversation = await this.rootStore.messageStore
      .toggleMute(this.conversation.id)
      .catch((err) => {
        this.rootStore.flashMessageStore
          .create(extractMessageFromError(err))
          .failed()
      })
    if (conversation) {
      this.previousMessage = this.rootStore.flashMessageStore
        .create({
          message:
            'Conversation notifications' +
            (conversation.muted ? ' muted.' : ' unmuted.'),
          id: this.previousMessage?.id,
        })
        .done()
        .autoDismiss(4000)
    }
  }

  /**
   * NOTE: This was copied from the wizard.
   */
  async fetchBillingMethods(project) {
    const billingStore = this.rootStore.billingStore
    return project.ownerTeam
      ? billingStore.fetchBillingProfileForTeam(project.ownerTeam.id)
      : billingStore.fetchBillingProfile()
  }

  /**
   * NOTE: This was copied from the wizard.
   */
  @computed
  get canManageBilling() {
    const job = this.project
    if (!job) return false
    const team = job.ownerTeam
    if (!team) return true // Non-team job, user can add own billing method.

    const teamMember = this.wizard.rootStore.teamMemberStore.forTeamAndUser(
      team.id,
      this.wizard.rootStore.sessionStore.member.userId
    )
    if (!teamMember) return false
    return teamMember.hasPermission('MANAGE_TEAM_BILLING')
  }

  /**
   * DISCLAIMER: this code sucks, but the existing checkout code in Wizard
   * sucked too and my fucks are gone.
   *
   * This only supports adding a new Stripe card method, not credit.
   *
   * @returns {Promise<void>}
   */
  triggerProvidePaymentInfoFlow = task.resolved(async () => {
    const job = this.project
    await this.fetchBillingMethods(job)
    if (!this.canManageBilling) {
      return
    }

    const profile = job.ownerTeam
      ? this.rootStore.billingStore.teamBillingProfile(job.ownerTeam.id)
      : this.rootStore.billingStore.individualBillingProfile

    let billingMethod
    if (profile.billingMethods.length === 0) {
      billingMethod = await this.addBillingMethod()
    } else {
      const defaultMethod = profile.billingMethods.find(
        (bm) => bm.id === profile.defaultBillingMethodId
      )
      billingMethod = await this.selectBillingMethodState.select(defaultMethod)
    }

    if (billingMethod) {
      const msg = this.rootStore.flashMessageStore.create({
        message: 'Processing payment information...',
        type: FlashMessageTypes.DEFAULT,
        inProgress: true,
      })
      try {
        await this.rootStore.projectStore.providePaymentInfo(
          job.id,
          billingMethod.id
        )
        msg
          .done(
            'Payment information has been submitted. If you have an outstanding balance then a charge will be attempted shortly and you will be notified.'
          )
          .autoDismiss(20000)
        this.rootStore.trackingStore.setDataLayer('paymentSettled', {
          jobId: job.id,
        })
      } catch (err) {
        msg.failed(extractMessageFromError(err))
      }
    }
  })

  async addBillingMethod() {
    const config = {
      team: this.project.ownerTeam,
      currency: 'USD',
      description: this.project.name,
      email: this.rootStore.authStore.user.email,
      amount: this.project.total * 100,
    }

    return this.rootStore.billingStore.showStripeCheckout(config)
  }

  @computed
  get hasPendingScopeChangeRequests() {
    const scopeChangeRequests = this.rootStore.scopeChangeRequestStore.forJob(
      this.project.id
    )

    return scopeChangeRequests.some(
      (scopeChangeRequest) => scopeChangeRequest.isPending
    )
  }

  @computed
  get canRate() {
    if (!this.project.client.isCurrentUser) {
      return false
    }

    if (this.jobProgressionFlowEnabled) {
      return this.isInOrPastDraftApproved
    }

    return this.project.status === 'CLOSED'
  }

  @computed
  get canRatePlatform() {
    if (!this.canRate || !this.ratePlatformDialogViewModel.canRatePlatform) {
      return false
    }

    return this.project.legend.ratingConfig?.platformRatingEnabled || false
  }

  @computed
  get canRatePro() {
    if (!this.canRate || !this.rateProDialogViewModel.canRatePro) {
      return false
    }

    return this.project.legend.ratingConfig?.proRatingEnabled || false
  }

  @computed
  get ratingMethod() {
    return (
      this.project.legend.ratingConfig?.customerRatingMethod?.toLowerCase() ||
      'stars'
    )
  }

  @computed
  get canLinkExistingDocuments() {
    const workspaceConfig = this.rootStore.sessionStore.workspace.config

    return (
      workspaceConfig.job_details_config?.['documents.allow_linking'] ?? true
    )
  }
}
