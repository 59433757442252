import last from 'lodash/last'
import without from 'lodash/without'

/**
 * Formats an array of years correctly, e.g.
 * - 2012
 * - 2012 & 2015
 * - 2012, 2015 & 2016
 *
 * @param  {number[]} years
 * @return {string}
 */
export function yearsToString(years) {
  if (years.length === 0) return ''
  if (years.length === 1) return years[0].toString()
  if (years.length === 2) return years.join(' & ')

  const lastYear = last(years)
  const exceptLast = without(years, lastYear)
  return `${exceptLast.join(', ')} & ${lastYear}`
}

/**
 * Obscures the first 5 digits of a Social Security
 * @param {string} ssnLikeString
 * @returns {string}
 */
export function obscureSsn(ssnLikeString) {
  return ssnLikeString.replace(/\d{3}-\d{2}-(\d{4})/, '✶✶✶-✶✶-$1')
}

/**
 * Obscures all but the last 4 numbers of Bank Account/Routing Numbers
 * @param {string} bankAccountLikeString
 * @returns {string}
 */
export function obscureBankNumbers(bankAccountLikeString) {
  const length = bankAccountLikeString.length
  if (length <= 4) return '*'.replace(length)
  return '✶'.repeat(length - 4) + bankAccountLikeString.slice(-4)
}
