import { action, observable } from 'mobx'
import { validationContext, func } from 'validx'
import { PhysicalAddressFormViewModel } from '../../../PhysicalAddressForm/PhysicalAddressFormViewModel'
import { TaxRefundDeliveryMethodType } from 'src/client/domain/tax-refund-method/TaxRefundMethodTypes'

export class CheckFormViewModel {
  @observable address = new PhysicalAddressFormViewModel()

  /**
   * Whether we are collecting the address for Personal Check
   */
  @observable skipAddressCollection = false

  validation = validationContext(this, {
    address: [
      func(({ value }) => {
        if (this.skipAddressCollection) {
          return true
        }

        if (value && value.validation) {
          return value.validation.reset().validate().isValid
        }
      }),
    ],
  })

  @action
  parse(json) {
    this.skipAddressCollection = !json
    this.address.parse(json)
  }

  toDto() {
    return {
      type: TaxRefundDeliveryMethodType.Check,
      mailingAddress: this.skipAddressCollection
        ? null
        : this.address.addressDto,
    }
  }

  /**
   * Sets value for skipAddressCollection
   * @param {boolean} boolValue
   */
  @action.bound
  setSkipAddressCollection(boolValue) {
    this.skipAddressCollection = boolValue
  }
}
