import { observable } from 'mobx'
import { Model } from 'libx'
import { maybeParseDate } from 'utils/dateUtil'

export default class DependentProfileVersion extends Model {
  @observable
  id = ''

  @observable
  version = 0

  @observable
  workspaceId = ''

  @observable
  ownerUserId = ''

  @observable
  personalInfo = null

  @observable
  relationship = ''

  @observable
  createTime = new Date()

  @observable
  updateTime = new Date()

  parse(json) {
    return {
      id: json.id,
      version: json.version,
      workspaceId: json.workspaceId,
      ownerUserId: json.ownerUserId,
      personalInfo: this.toPersonalInfoDTO(json.personalInfo),
      relationship: json.relationship,
      createTime: maybeParseDate(json.createTime),
      updateTime: maybeParseDate(json.updateTime),
    }
  }

  toPersonalInfoDTO(personalInfo) {
    if (!personalInfo) {
      return personalInfo
    }
    return {
      firstName: personalInfo.firstName,
      middleInitial: personalInfo.middleInitial,
      lastName: personalInfo.lastName,
      dateOfBirth: personalInfo.dateOfBirth,
      personalTaxpayerIdentificationNumber:
        personalInfo.personalTaxpayerIdentificationNumber,
    }
  }
}
