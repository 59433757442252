import { validationContext, required } from 'validx'
import { action, observable } from 'mobx'
import moment from 'moment'
import { validateSsn } from 'src/client/utils/validx-validators'

export class PersonalInfoViewModel {
  @observable firstName
  @observable middleInitial
  @observable lastName
  @observable dateOfBirth
  @observable personalTaxpayerIdentificationNumber

  validation = validationContext(this, {
    firstName: [required('First name is required')],
    lastName: [required('Last name is required')],
    dateOfBirth: [required('Date of birth is required')],
    personalTaxpayerIdentificationNumber: [
      required('SSN or ITIN is required'),
      validateSsn(),
    ],
  })

  @action
  parse(json) {
    this.firstName = json?.firstName
    this.middleInitial = json?.middleInitial
    this.lastName = json?.lastName
    this.dateOfBirth = json?.dateOfBirth
      ? moment(json.dateOfBirth).format('YYYY-MM-DD')
      : undefined
    this.personalTaxpayerIdentificationNumber =
      json?.personalTaxpayerIdentificationNumber
  }

  toDto() {
    return {
      firstName: this.firstName,
      middleInitial: this.middleInitial,
      lastName: this.lastName,
      dateOfBirth: moment(this.dateOfBirth).format('MM/DD/yyyy'),
      personalTaxpayerIdentificationNumber:
        this.personalTaxpayerIdentificationNumber,
    }
  }
}
