import { Model } from 'libx'
import { observable, computed } from 'mobx'
import { decimalToNumber, timestampToISO } from 'utils/grpcUtil'
import { maybeParseDate } from 'utils/dateUtil'
import { ScopeChangeStatus } from 'jobs/ScopeChangeRequest'
import ScopeChangeRequestBreakdownItem from './ScopeChangeRequestBreakdownItem'

export default class ScopeChangeRequestBreakdown extends Model {
  @observable id = null
  @observable status = null
  @observable items = []
  @observable previousScopePrice = 0
  @observable newScopePrice = 0
  @observable dateResponded = null

  @computed
  get isPendingClientApproval() {
    return this.status === 'PendingClientApproval'
  }

  @computed
  get isAccepted() {
    return this.status === 'Accepted'
  }

  @computed
  get isRejected() {
    return this.status === 'Rejected'
  }

  /**
   * Returns difference between previous and new scoped prices.
   */
  @computed
  get formattedAmountDue() {
    return (
      (this.amountDue < 0 ? '-' : '') +
      '$' +
      Math.abs(this.amountDue).toFixed(2)
    )
  }

  @computed
  get amountDue() {
    return this.newScopePrice - this.previousScopePrice
  }

  /**
   * Parses the DTO to model properties.
   */
  parse(attrs) {
    return {
      id: attrs.id,
      status: ScopeChangeStatus[attrs.status],
      items:
        attrs.itemsList &&
        attrs.itemsList.map(
          (item) =>
            new ScopeChangeRequestBreakdownItem({
              inventoryItemVersionId: item.inventoryItemVersionId?.value,
              name: item.name,
              description: item.description,
              previousQuantity: item.previousQuantity,
              newQuantity: item.newQuantity,
              previousPrice: maybeDecimalToNumber(item.previousPrice),
              newPrice: maybeDecimalToNumber(item.newPrice),
            })
        ),
      previousScopePrice: maybeDecimalToNumber(attrs.previousScopePrice),
      newScopePrice: maybeDecimalToNumber(attrs.newScopePrice),
      dateResponded: maybeParseDate(timestampToISO(attrs.dateResponded)),
    }
  }
}

function maybeDecimalToNumber(value) {
  return value ? decimalToNumber(value) : 0
}
