import MobileDetect from 'mobile-detect'

const md = new MobileDetect(window.navigator.userAgent)

export function isIPhoneSafari() {
  return md.is('iOS') && md.is('iPhone')
}

export function isAndroid() {
  return md.is('AndroidOS')
}

export function isMobile() {
  return isIPhoneSafari() || isAndroid() || isMobileViewport()
}

export function inIframe() {
  return window.location !== window.parent?.location
}

// This uses the same params as our Bulma mixin
// Anything less than a tablet is considered mobile
// $tablet: 769px !default;
export function isMobileViewport(maxWidth = 768) {
  return window.matchMedia(`(max-width: ${maxWidth}px)`).matches
}
