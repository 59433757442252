const link = {
  logout: () => '/logout',
  login: () => '/login',
  projects: () => '/',
  projectDetails: (projectId, hideNavbar) =>
    `/project/${projectId}${hideNavbar ? '?hideNavbar=true' : ''}`,
  projectEditor: (projectId, hideNavbar, fromDiy) => {
    const hideNavebarQs = hideNavbar ? 'hideNavbar=true' : ''
    const fromDiyQs = fromDiy ? 'fromDiy=true' : ''

    if (hideNavbar && fromDiy) {
      return `/project/${projectId}/edit?${hideNavebarQs}&${fromDiyQs}`
    }

    if (hideNavbar) {
      return `/project/${projectId}/edit?${hideNavebarQs}`
    }

    if (fromDiy) {
      return `/project/${projectId}/edit?${fromDiyQs}`
    }

    return `/project/${projectId}/edit`
  },
  projectRoom: (projectId, roomId, hideNavbar) =>
    `/project/${projectId}/rooms/${roomId}${
      hideNavbar ? '?hideNavbar=true' : ''
    }`,
  allDocuments: () => '/documents',
  profile: () => '/profile',
  linkedAccounts: () => '/linked-accounts',
  coupons: () => '/coupons',
  referAFriend: () => '/refer',
  newProject: (legendServiceType) =>
    legendServiceType ? `/new/${legendServiceType}` : '/new',
  diy: ({ hideNavbar, consultationJobId }) => {
    const hideNavbarQs = hideNavbar ? 'hideNavbar=true' : ''
    const consultationJobIdQs = consultationJobId
      ? `consultationJobId=${consultationJobId}`
      : ''

    if (hideNavbar && consultationJobId) {
      return `/diy?${hideNavbarQs}&${consultationJobIdQs}`
    }

    if (hideNavbar) {
      return `/diy?${hideNavbarQs}`
    }

    if (consultationJobId) {
      return `/diy?${consultationJobIdQs}`
    }

    return '/diy'
  },
  billing: () => '/billing',
  teams: () => '/teams',
  notificationPreferences: () => '/notifications/preferences',
  conversation: (conversation) => {
    if (conversation.meta.type === 'Job') {
      const jobId = conversation.meta.id
      return link.projectDetails(jobId)
    }
    console.warn('Tried to navigate to a conversation not in a Job somehow')
    return link.welcome()
  },
}

export default link
