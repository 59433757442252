import { observable, action } from 'mobx'
import {
  BankAccountType,
  TaxRefundDeliveryMethodType,
} from 'src/client/domain/tax-refund-method/TaxRefundMethodTypes'
import { validationContext, required, func } from 'validx'

export class DirectDepositFormViewModel {
  @observable accountType = BankAccountType.Checking
  @observable accountNumber
  @observable routingNumber

  validation = validationContext(this, {
    accountType: [
      required(),
      func(({ value }) => {
        return value !== BankAccountType.Unspecified
      }),
    ],
    accountNumber: [
      required('Accounting Number is required'),
      // This changes if the bank is in another country
      // so probably shouldn't do this unless we only do US banks
      func(({ value }) => {
        const accountNumberRegex = /^\d{6,17}$/
        return (
          accountNumberRegex.test(value) || 'Please use a valid Account Number'
        )
      }),
    ],
    routingNumber: [
      required('Routing Number is required'),
      // This changes if the bank is in another country
      // so probably shouldn't do this unless we only do US banks
      func(({ value }) => {
        const routingNumberRegex = /^\d{9}$/
        return (
          routingNumberRegex.test(value) || 'Please use a valid Routing Number'
        )
      }),
    ],
  })

  @action
  parse(json) {
    if (!json) {
      return
    }

    this.accountType = json.accountType
    this.accountNumber = json.accountNumber
    this.routingNumber = json.routingNumber
  }

  @action.bound
  setAccountType(accountType) {
    this.accountType = accountType
  }

  toDto() {
    return {
      type: TaxRefundDeliveryMethodType.DirectDeposit,
      bankAccount: {
        accountType: this.accountType,
        accountNumber: this.accountNumber,
        routingNumber: this.routingNumber,
      },
    }
  }
}
