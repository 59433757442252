import {
  TaxRefundDeliveryMethod,
  CheckTaxRefundDeliveryMethod,
  DirectDepositTaxRefundDeliveryMethod,
  FutureTaxCreditTaxRefundDeliveryMethod,
  BankAccountDetails,
  BankAccountType as BankAccountTypeProto,
  TaxRefundType as TaxRefundTypeProto,
} from '@taxfyle/api-internal/internal/tax_refund_methods_pb'
import { physicalAddressToProto } from './infoGatheringProfileGrpcMapper'
import {
  BankAccountType,
  TaxRefundType,
  TaxRefundDeliveryMethodType,
} from '../domain/tax-refund-method/TaxRefundMethodTypes'

export function refundTypeToProto(refundType) {
  switch (refundType) {
    case TaxRefundType.Personal:
      return TaxRefundTypeProto.PERSONAL
    case TaxRefundType.Business:
      return TaxRefundTypeProto.BUSINESS
    default:
      return TaxRefundTypeProto.TAX_REFUND_TYPE_UNSPECIFIED
  }
}

export function deliveryMethodToProto(deliveryMethodDto) {
  if (!deliveryMethodDto) {
    return null
  }

  const deliveryMethodProto = new TaxRefundDeliveryMethod()

  switch (deliveryMethodDto.type) {
    case TaxRefundDeliveryMethodType.Check:
      return deliveryMethodProto.setCheck(
        new CheckTaxRefundDeliveryMethod().setMailingAddress(
          physicalAddressToProto(deliveryMethodDto.mailingAddress)
        )
      )
    case TaxRefundDeliveryMethodType.DirectDeposit:
      return deliveryMethodProto.setDirectDeposit(
        // TODO: only set bank account if we have one
        new DirectDepositTaxRefundDeliveryMethod().setBankAccount(
          bankAccountToProto(deliveryMethodDto.bankAccount)
        )
      )
    case TaxRefundDeliveryMethodType.FutureTaxCredit:
      return deliveryMethodProto.setFutureTaxCredit(
        new FutureTaxCreditTaxRefundDeliveryMethod()
      )
    default:
      return null
  }
}

export function bankAccountToProto(bankAccount) {
  if (!bankAccount) {
    return null
  }

  return new BankAccountDetails()
    .setAccountType(bankAccountTypeToProto(bankAccount.accountType))
    .setAccountNumber(bankAccount.accountNumber)
    .setRoutingNumber(bankAccount.routingNumber)

  function bankAccountTypeToProto(bankAccountType) {
    switch (bankAccountType) {
      case BankAccountType.Checking:
        return BankAccountTypeProto.CHECKING
      case BankAccountType.Savings:
        return BankAccountTypeProto.SAVINGS
      default:
        return BankAccountTypeProto.BANK_ACCOUNT_TYPE_UNSPECIFIED
    }
  }
}
