export default function makeAuthenticate({ authStore }) {
  return async function authenticate(nextState, replace, callback) {
    const { show, email, connection, error_description } =
      nextState.location.query
    const isAuthenticated = await authStore.checkAuthentication()
    if (!isAuthenticated) {
      const origin = window.localStorage.getItem('accessOrigin')
      if (show === 'login' || show === 'signup') {
        return authStore.login(show, { login_hint: email, connection })
      } else if (origin === 'mobile') {
        replace({
          pathname: '/login',
          state: { nextPathname: nextState.location.pathname },
          query: { error: 'invalid_token' },
        })
      } else {
        replace({
          pathname: '/login',
          state: { nextPathname: nextState.location.pathname },
          ...(error_description && { query: { error_description } }),
        })
      }
    }

    callback()
  }
}
