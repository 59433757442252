import { TaxRefundDeliveryMethodType } from 'src/client/domain/tax-refund-method/TaxRefundMethodTypes'

export class FutureTaxCreditFormViewModel {
  toDto() {
    return {
      type: TaxRefundDeliveryMethodType.FutureTaxCredit,
    }
  }

  parse(_) {}
}
