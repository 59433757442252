import { Store } from 'libx'
import { Checklist } from '../domain/info-gathering/Checklist'
import {
  DependentsInfoSupportingItemInput,
  DocumentSupportingItemInput,
  PersonalInfoSupportingItemInput,
  SpousalInfoSupportingItemInput,
  BusinessInfoSupportingItemInput,
  BusinessRefundMethodSupportingItemInput,
  PersonalRefundMethodSupportingItemInput,
} from '../domain/info-gathering/RequestedSupportingItem'
import { RealtimeEvent } from '@taxfyle/api-internal/internal/realtime_pb'
import { timestampToISO } from 'utils/grpcUtil'
import { maybeParseDate } from 'utils/dateUtil'

/**
 * Info gathering store.
 */
export class InfoGatheringStore extends Store {
  /**
   * Info gathering checklists, ID'ed by the job.
   */
  checklists = this.collection({
    model: Checklist,
  })

  constructor({ rootStore }) {
    super({ rootStore })
    this.api = rootStore.api
    this.api.infoGathering.events$.subscribe(this._onRemoteEvent.bind(this))
  }

  /**
   * Get the checklist for a job.
   */
  async fetchChecklist(jobId) {
    const response = await this.api.infoGathering.getChecklist({ jobId })
    return this.checklists.set({
      id: jobId,
      ...mapChecklistProtoToDto(response),
    })
  }

  /**
   * Provides the document to the supporting item.
   *
   * @param jobId
   * @param documentId
   * @param {RequestedSupportingItem} supportingItem
   * @returns {Promise<void>}
   */
  async provideDocument(jobId, documentId, supportingItem) {
    if (!(supportingItem.input instanceof DocumentSupportingItemInput)) {
      throw new Error('Invalid supporting item type')
    }

    await this.api.infoGathering.provideSupportingItemDocument({
      jobId,
      documentId,
      supportingItemId: supportingItem.id,
    })

    supportingItem.input.addDocument(documentId)
  }

  /**
   * Removes the document from the supporting item.
   *
   * @param documentId
   * @param {RequestedSupportingItem} supportingItem
   * @returns {Promise<void>}
   */
  async removeDocument(documentId, supportingItem) {
    if (!(supportingItem.input instanceof DocumentSupportingItemInput)) {
      throw new Error('Invalid supporting item type')
    }

    await this.api.infoGathering.removeSupportingItemDocument({
      documentId,
      supportingItemId: supportingItem.id,
    })

    supportingItem.input.removeDocument(documentId)
  }

  async provideBusinessInfo(profileId, profileVersion, jobId, supportingItem) {
    if (!(supportingItem.input instanceof BusinessInfoSupportingItemInput)) {
      throw new Error('Invalid supporting item')
    }

    await this.api.infoGathering.provideSupportingItemBusinessInfo({
      profileId,
      profileVersion,
      supportingItemId: supportingItem.id,
      jobId,
    })

    supportingItem.input.setProfile(profileId, profileVersion)
  }

  /**
   * Provides the personal info to the supporting item.
   *
   * @param {*} jobId
   * @param {*} documentId
   * @param {*} supportingItem
   */
  async providePersonalInfo(jobId, supportingItem, profileId, profileVersion) {
    if (!(supportingItem.input instanceof PersonalInfoSupportingItemInput)) {
      throw new Error('Invalid supporting item type')
    }

    await this.api.infoGathering.provideSupportingItemPersonalInfo({
      jobId,
      supportingItemId: supportingItem.id,
      profileId,
      profileVersion,
    })

    supportingItem.input.addProfile(profileId, profileVersion)
  }

  /**
   * Provides the spousal info to the supporting item.
   *
   * @param {*} supportingItem
   * @param {*} jobId
   * @param {*} profileId
   * @param {*} profileVersion
   */
  async provideSpousalInfo(jobId, supportingItem, profileId, profileVersion) {
    if (!(supportingItem.input instanceof SpousalInfoSupportingItemInput)) {
      throw new Error('Invalid supporting item type')
    }

    await this.api.infoGathering.provideSupportingItemSpousalInfo({
      jobId,
      supportingItemId: supportingItem.id,
      profileId,
      profileVersion,
    })

    supportingItem.input.addProfile(profileId, profileVersion)
  }

  /**
   * Provides the personal info to the supporting item.
   *
   * @param {*} jobId
   * @param {*} documentId
   * @param {*} supportingItem
   */
  async provideDependentInfo(jobId, supportingItem, profileId, profileVersion) {
    if (!(supportingItem.input instanceof DependentsInfoSupportingItemInput)) {
      throw new Error('Invalid supporting item type')
    }

    await this.api.infoGathering.provideSupportingItemDependentInfo({
      jobId,
      supportingItemId: supportingItem.id,
      profileId,
      profileVersion,
    })

    supportingItem.input.addDependent(profileId, profileVersion)
  }

  /**
   * Provides the personal refund method info to the supporting item.
   */
  async providePersonalRefundMethod(
    refundMethodId,
    refundMethodVersion,
    jobId,
    supportingItem
  ) {
    if (
      !(supportingItem.input instanceof PersonalRefundMethodSupportingItemInput)
    ) {
      throw new Error('Invalid supporting item')
    }

    await this.api.infoGathering.provideSupportingItemPersonalRefundMethod({
      refundMethodId,
      refundMethodVersion,
      supportingItemId: supportingItem.id,
      jobId,
    })
  }

  /**
   * Provides the business refund method info to the supporting item.
   */
  async provideBusinessRefundMethod(
    refundMethodId,
    refundMethodVersion,
    jobId,
    supportingItem
  ) {
    if (
      !(supportingItem.input instanceof BusinessRefundMethodSupportingItemInput)
    ) {
      throw new Error('Invalid supporting item')
    }

    await this.api.infoGathering.provideSupportingItemBusinessRefundMethod({
      refundMethodId,
      refundMethodVersion,
      supportingItemId: supportingItem.id,
      jobId,
    })
  }

  /**
   * Removes the dependent info from the supporting item.
   *
   * @param {*} jobId
   * @param {*} documentId
   * @param {*} supportingItem
   */
  async removeDependentInfo(jobId, supportingItem, profileId) {
    if (!(supportingItem.input instanceof DependentsInfoSupportingItemInput)) {
      throw new Error('Invalid supporting item type')
    }

    await this.api.infoGathering.removeSupportingItemDependentInfo({
      jobId,
      supportingItemId: supportingItem.id,
      profileId,
    })

    supportingItem.input.removeDependent(profileId)
  }

  /**
   * Called when the client receives an event from the server.
   *
   * @param event
   * @private
   */
  _onRemoteEvent(event) {
    switch (event.getTypeCase()) {
      case RealtimeEvent.TypeCase.REQUESTED_SUPPORTING_ITEM_UPDATED: {
        return this._onRemoteItemUpdated(
          event.getRequestedSupportingItemUpdated().toObject()
        )
      }
      case RealtimeEvent.TypeCase.DOCUMENT_REQUEST_CREATED: {
        return this.fetchChecklist(event.getDocumentRequestCreated().getJobId())
      }
    }
  }

  /**
   * Handles a requested supporting item update from the server.
   * @private
   */
  _onRemoteItemUpdated(event) {
    const checklist = this.checklists.get(event.jobId)
    if (!checklist) {
      return
    }

    const dto = mapItemProtoToDto(event.requestedSupportingItem)
    const item = checklist.getItemById(dto.id)
    if (!item) {
      return
    }

    // Only update if the server timestamp is more recent.
    const lastUpdated = maybeParseDate(dto.update_time)
    if (!item.dateModified || item.dateModified < lastUpdated) {
      item.set(dto, {
        parse: true,
        stripUndefined: true,
      })
    }
  }
}

function mapChecklistProtoToDto(checklistProto) {
  if (!checklistProto) {
    return checklistProto
  }

  return {
    sections: checklistProto.sectionsList.map((sectionProto) => ({
      id: sectionProto.id,
      name: sectionProto.name,
      heading: sectionProto.heading,
      description: sectionProto.description,
      requested_supporting_items: sectionProto.requestedSupportingItemsList.map(
        (itemProto) => mapItemProtoToDto(itemProto)
      ),
    })),
  }
}

function mapItemProtoToDto(itemProto) {
  if (!itemProto) {
    return itemProto
  }

  return {
    id: itemProto.id,
    name: itemProto.name,
    heading: itemProto.heading,
    short_description: itemProto.shortDescription,
    long_description: itemProto.longDescription,
    image_url: itemProto.imageUrl,
    preview_url: itemProto.previewUrl,
    required: itemProto.required,
    completed: itemProto.completed,
    input: mapInputProtoToDto(itemProto.input),
    update_time: timestampToISO(itemProto.updateTime),
    ad_hoc: itemProto.adHoc,
  }
}

function mapInputProtoToDto(inputProto) {
  if (!inputProto) {
    return inputProto
  }

  switch (true) {
    case !!inputProto.document: {
      return {
        type: SupportingItemInputTypeDiscriminatorDTO.DOCUMENT,
        document_type_short_id: inputProto.document.documentTypeShortId,
        document_ids: inputProto.document.documentIdsList,
      }
    }
    case !!inputProto.personalInfo: {
      return {
        type: SupportingItemInputTypeDiscriminatorDTO.PERSONAL_INFO,
        profile_id: inputProto.personalInfo.profileId?.value,
        profile_version: inputProto.personalInfo.profileVersion?.value,
      }
    }
    case !!inputProto.spousalInfo: {
      return {
        type: SupportingItemInputTypeDiscriminatorDTO.SPOUSAL_INFO,
        profile_id: inputProto.spousalInfo.profileId?.value,
        profile_version: inputProto.spousalInfo.profileVersion?.value,
      }
    }
    case !!inputProto.dependentsInfo: {
      return {
        type: SupportingItemInputTypeDiscriminatorDTO.DEPENDENTS_INFO,
        dependents:
          inputProto.dependentsInfo.dependentsList?.map((dependentProto) => ({
            profile_id: dependentProto.profileId?.value,
            profile_version: dependentProto.profileVersion?.value,
          })) || [],
      }
    }
    case !!inputProto.personalRefundMethod: {
      return {
        type: SupportingItemInputTypeDiscriminatorDTO.PERSONAL_REFUND_METHOD_INFO,
        refund_method_id: inputProto.personalRefundMethod.refundMethodId?.value,
        refund_method_version:
          inputProto.personalRefundMethod.refundMethodVersion?.value,
      }
    }
    case !!inputProto.businessInfo: {
      return {
        type: SupportingItemInputTypeDiscriminatorDTO.BUSINESS_INFO,
        profile_id: inputProto.businessInfo.profileId?.value,
        profile_version: inputProto.businessInfo.profileVersion?.value,
      }
    }
    case !!inputProto.businessRefundMethod: {
      return {
        type: SupportingItemInputTypeDiscriminatorDTO.BUSINESS_REFUND_METHOD_INFO,
        refund_method_id: inputProto.businessRefundMethod.refundMethodId?.value,
        refund_method_version:
          inputProto.businessRefundMethod.refundMethodVersion?.value,
      }
    }
    case !!inputProto.notes: {
      return {
        type: SupportingItemInputTypeDiscriminatorDTO.NOTES,
        text: inputProto.notes.text?.value,
      }
    }
  }

  console.error('Unknown supporting item input', inputProto)
  return null
}

export const SupportingItemInputTypeDiscriminatorDTO = Object.freeze({
  DOCUMENT: 'DOCUMENT',
  PERSONAL_INFO: 'PERSONAL_INFO',
  SPOUSAL_INFO: 'SPOUSAL_INFO',
  DEPENDENTS_INFO: 'DEPENDENTS_INFO',
  PERSONAL_REFUND_METHOD_INFO: 'PERSONAL_REFUND_METHOD_INFO',
  BUSINESS_INFO: 'BUSINESS_INFO',
  BUSINESS_REFUND_METHOD_INFO: 'BUSINESS_REFUND_METHOD_INFO',
  NOTES: 'NOTES',
})
