import { Store } from 'libx'
import { isMobile } from 'src/client/utils/mobileDetect'

export default class HubspotChatStore extends Store {
  enabled(workspace) {
    return Boolean(
      workspace?.config?.hubspot_chat_config?.customer_portal_enabled
    )
  }

  isDiyPage() {
    return window.location.pathname.includes('/diy')
  }

  maybeActivateAndLoadWidget(workspace, member) {
    if (!this.enabled(workspace)) {
      // This is to remove the chat widget when switching to a workspace that doesn't have it enabled.
      this.removeWidget()
      return
    }

    try {
      this.rootStore.freshbooksAPI.getToken().then((result) => {
        const token = result?.token
        window.hsConversationsSettings = {
          identificationEmail: member.email,
          identificationToken: token,
        }

        // Mobile web and DIY page has a seperate button for opening the chat widget.
        if (isMobile() || this.isDiyPage()) {
          return
        }

        this.loadWidget()
      })
    } catch (error) {
      console.log('Error loading HubSpot chat', error)
    }

    // Listener to remove the widget when it's closed on mobile or on DIY page.
    window?.HubSpotConversations?.on('widgetClosed', () => {
      if (this.shouldNotShowWidget()) {
        this.removeWidget()
      }
    })
  }

  shouldNotShowWidget() {
    return isMobile() || this.isDiyPage()
  }

  loadWidget() {
    window?.HubSpotConversations?.widget?.load()
  }

  maybeLoadWidget(workspace) {
    if (!this.enabled(workspace) || this.shouldNotShowWidget()) {
      return
    }

    this.loadWidget()
  }

  openWidget() {
    window?.HubSpotConversations?.widget?.open()
  }

  maybeLoadAndOpenWidget(workspace) {
    if (!this.enabled(workspace)) {
      return
    }

    this.loadWidget()
    this.openWidget()
  }

  removeWidget() {
    window?.HubSpotConversations?.widget?.remove()
  }
}
