import { ContactInformation } from '@taxfyle/api-internal/shared/types/contact_info_pb'
import { PhysicalAddress } from '@taxfyle/api-internal/shared/types/physical_address_pb'
import { PersonalInfo } from '@taxfyle/api-internal/internal/individual_profile_pb'
import { BusinessInfo } from '@taxfyle/api-internal/internal/business_profile_pb'

export function personalInfoToProto(personalInfo) {
  if (!personalInfo) {
    return null
  }

  return new PersonalInfo()
    .setFirstName(personalInfo.firstName)
    .setMiddleInitial(personalInfo.middleInitial)
    .setLastName(personalInfo.lastName)
    .setDateOfBirth(personalInfo.dateOfBirth)
    .setPersonalTaxpayerIdentificationNumber(
      personalInfo.personalTaxpayerIdentificationNumber
    )
}

export function contactInfoToProto(contactInfo) {
  if (!contactInfo) {
    return null
  }

  return new ContactInformation()
    .setPhoneNumber(contactInfo.phoneNumber)
    .setEmail(contactInfo.email)
    .setAddress(physicalAddressToProto(contactInfo.contactAddress))
}

export function physicalAddressToProto(address) {
  if (!address) {
    return null
  }

  return new PhysicalAddress()
    .setAddressLine1(address.addressLine1)
    .setAddressLine2(address.addressLine2)
    .setCity(address.city)
    .setRegion(address.region)
    .setPostalCode(address.postalCode)
    .setCountry(address.country)
}

export function businessInfoToProto(businessInfo) {
  if (!businessInfo) {
    return null
  }

  return new BusinessInfo()
    .setBusinessName(businessInfo.businessName)
    .setDateOfIncorporation(businessInfo.dateOfIncorporation)
    .setEmployerIdentificationNumber(businessInfo.employerIdentificationNumber)
}
