import { Store } from 'libx'
import { observable, action } from 'mobx'

export default class JobTaxAssistantInfoDialogStore extends Store {
  @observable
  showing = false

  @action.bound
  async show() {
    this.showing = true
  }

  @action.bound
  close() {
    this.showing = false
  }
}
