import { observable, action, computed, runInAction } from 'mobx'
import { task } from 'mobx-task'
import defer from 'promise-defer'
import { Store } from 'libx'
import { defaultCancellationToken } from '@taxfyle/web-commons/lib/utils/cancellation'

export default class ConfirmScheduleDialogStore extends Store {
  @observable
  showing = false

  @observable
  success = false

  @observable
  isReschedule = false

  /**
   * Indicates that, upon trying to submit the new schedule, a conflict
   * occurred that requires the user to select new times.
   * @type {boolean}
   */
  @observable
  timeSlotConflictOccurred = false

  @observable
  timeSlots = []

  @computed
  get consultation() {
    return this.rootStore.scheduleStore.consultation
  }

  @computed
  get consultationLength() {
    return this.consultation?.requestedLength || 60
  }

  @computed
  get disabled() {
    return this.timeSlotConflictOccurred
  }

  @action
  async show(isReschedule = false) {
    if (this.deferredRequest) {
      this.deferredRequest.resolve(false)
    }

    this.timeSlots = this.rootStore.scheduleStore.scheduledAvailability
      .slice()
      .map((d) => ({
        day: d.day,
        timeSlotsConsolidated: d.timeSlotsConsolidated.slice(),
      }))

    this.isReschedule = isReschedule
    this.timeSlotConflictOccurred = false
    this.showing = true
    this.deferredRequest = defer()
    this.shouldShowPhoneNumber =
      !this.consultation?.availability &&
      this.rootStore.scheduleStore.isPhoneCall

    return this.deferredRequest.promise
  }

  @action
  close() {
    this._resolve()
    this.showing = false
    this.success = false
    this.isReschedule = false
    this.timeSlots = []
  }

  @action
  back() {
    this.close()
    this.rootStore.projectDetailsStore.updateAvailability()
  }

  submit = task.resolved(async () => {
    await this.rootStore.scheduleStore.fetchBookedTimeRangesForPro(
      defaultCancellationToken,
      true
    )

    const selectedTimeIsAlreadyBooked = this.timeSlots
      .flatMap((d) => d.timeSlotsConsolidated)
      .some((x) =>
        this.rootStore.scheduleStore.isAlreadyBookedByProForOtherJob(x)
      )

    if (selectedTimeIsAlreadyBooked) {
      runInAction(() => {
        this.timeSlotConflictOccurred = true
      })

      this.rootStore.flashMessageStore.create({
        type: 'error',
        message:
          'Your Pro is unavailable on one or more of the time slots you selected. Please go back and select new times.',
      })

      return
    }

    await this.rootStore.scheduleStore.setConsultationAvailability().then(
      action(() => {
        this.showing = false
        this.success = !this.shouldShowPhoneNumber
      })
    )

    if (this.shouldShowPhoneNumber) {
      await this.rootStore.confirmPhoneDialogStore.show().then(
        action(() => {
          this.success = true
        })
      )
    }
    this._resolve()
  })

  _resolve = () => {
    if (!this.deferredRequest) return
    this.deferredRequest.resolve(true)
  }
}
