import axios from 'axios'
import env, { SUPPORTED_ENGINE_VERSION } from './env'
import { getFeathersEventManager } from '@taxfyle/web-commons/lib/misc/APIClient'
import { JobsV3API } from 'jobs/API'
import { enableDevTools } from '@taxfyle/web-commons/lib/utils/grpcUtil'
import { createGrpcAuthenticator } from '@taxfyle/web-commons/lib/misc/grpc-authenticator'
import memoize from 'memoizee'
import { ConsultationServicePromiseClient } from '@taxfyle/api-internal/internal/consultation_grpc_web_pb'
import { DiyServicePromiseClient } from '@taxfyle/api-internal/internal/diy_grpc_web_pb'
import {
  SetConsultationAvailabilityRequest,
  DownloadConsultationCalendarRequest,
  ListBookedTimeRangesForProRequest,
} from '@taxfyle/api-internal/internal/consultation_pb'
import {
  CreateDiyUrlRequest,
  LinkConsultationJobToDiyJobRequest,
  CheckTaxYearRequest,
  CheckDiyEnabledRequest,
  GetCurrentYearDiyJobRequest,
  HideDiyJobRequest,
} from '@taxfyle/api-internal/internal/diy_pb'
import { Interval } from '@taxfyle/api-internal/shared/types/interval_pb'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { InfoGatheringServicePromiseClient } from '@taxfyle/api-internal/internal/info_gathering_grpc_web_pb'
import { IndividualProfileServicePromiseClient } from '@taxfyle/api-internal/internal/individual_profile_grpc_web_pb'
import { BusinessProfileServicePromiseClient } from '@taxfyle/api-internal/internal/business_profile_grpc_web_pb'
import { TaxRefundMethodServicePromiseClient } from '@taxfyle/api-internal/internal/tax_refund_methods_grpc_web_pb'
import {
  GetChecklistRequest,
  ProvideSupportingItemDocumentRequest,
  ProvideSupportingItemBusinessInfoRequest,
  RemoveSupportingItemDocumentRequest,
  ProvideSupportingItemPersonalInfoRequest,
  ProvideSupportingItemSpousalInfoRequest,
  ProvideSupportingItemDependentInfoRequest,
  RemoveSupportingItemDependentInfoRequest,
  ProvideSupportingItemPersonalRefundMethodRequest,
  ProvideSupportingItemBusinessRefundMethodRequest,
} from '@taxfyle/api-internal/internal/info_gathering_pb'
import {
  GetTaxRefundMethodRequest,
  GetPersonalTaxRefundMethodForJobRequest,
  GetBusinessTaxRefundMethodForJobRequest,
  CreateTaxRefundMethodRequest,
  UpdateTaxRefundMethodRequest,
  TaxRefundType,
} from '@taxfyle/api-internal/internal/tax_refund_methods_pb'
import { RealtimeEvent } from '@taxfyle/api-internal/internal/realtime_pb'
import { ListJobEventsRequest } from '@taxfyle/api-internal/internal/job_event_pb'
import { JobEventsServicePromiseClient } from '@taxfyle/api-internal/internal/job_event_grpc_web_pb'
import { JobRatingServicePromiseClient } from '@taxfyle/api-internal/internal/job_rating_grpc_web_pb'
import {
  GetJobRatingStatusRequest,
  SubmitProRatingRequest,
  SubmitPlatformRatingRequest,
} from '@taxfyle/api-internal/internal/job_rating_pb'

import { filter } from 'rxjs/operators'
import {
  BookkeepingServicePromiseClient,
  QuickBooksPlatformConnectionServicePromiseClient,
  XeroPlatformConnectionServicePromiseClient,
} from '@taxfyle/api-internal/internal/bookkeeping_grpc_web_pb'
import {
  ListConnectedQuickBooksAccountsRequest,
  ListConnectedXeroAccountsRequest,
  CreateQuickBooksPlatformConnectionRequest,
  GetBookkeepingProgressionInfoRequest,
  ProvidePlatformConnectionRequest,
  MarkAccessAsGrantedRequest,
  QuickBooksPlatformConnection,
  XeroPlatformConnection,
  OtherPlatformConnection,
  CreateQuickBooksPlatformConnectionFromOAuth2CodeRequest,
  CreateXeroPlatformConnectionFromOAuth2CodeRequest,
} from '@taxfyle/api-internal/internal/bookkeeping_pb'
import { JobTaxAssistantServicePromiseClient } from '@taxfyle/api-internal/internal/job_tax_assistant_grpc_web_pb'
import {
  GetPersonalProfileRequest,
  GetPersonalProfileForJobRequest,
  SavePersonalProfileRequest,
  GetSpousalProfileRequest,
  GetSpousalProfileForJobRequest,
  SaveSpousalProfileRequest,
  GetDependentProfileRequest,
  CreateDependentProfileRequest,
  UpdateDependentProfileRequest,
} from '@taxfyle/api-internal/internal/individual_profile_pb'
import {
  businessInfoToProto,
  contactInfoToProto,
  personalInfoToProto,
} from '../utils/infoGatheringProfileGrpcMapper'

import {
  GetBusinessProfileForJobRequest,
  GetBusinessProfileRequest,
  CreateBusinessProfileRequest,
  UpdateBusinessProfileRequest,
} from '@taxfyle/api-internal/internal/business_profile_pb'
import { mapGrpcDiyJob } from '../utils/diyJobGrpcMapper'
import { deliveryMethodToProto } from '../utils/infoGatheringTaxRefundMethodGrpcMapper'

export default class TaxfyleAPI {
  constructor({ authStore, workRealtime$, togglesInitialized$ }) {
    this.authStore = authStore
    this.jobs = new JobsAPI(this)
    this.jobsV3 = new JobsV3API(
      {
        baseURL: env.WORK_DOTNET_API,
        token$: authStore.token$,
      },
      workRealtime$
    )
    this.providers = new ProviderAPI(this)
    this.jobEvents = new JobEventsAPI(this)
    this.jobEventsV3 = new JobEventsV3API(
      {
        baseURL: env.WORK_DOTNET_API,
        token$: authStore.token$,
      },
      workRealtime$
    )
    this.legends = new LegendsAPI(this)
    this.documentTypes = new DocumentTypeAPI(this)

    this.consultations = new ConsultationAPI({
      baseURL: env.WORK_DOTNET_API,
      token$: authStore.token$,
    })

    this.diy = new DiyAPI({
      baseURL: env.WORK_DOTNET_API,
      token$: authStore.token$,
    })

    this.infoGathering = new InfoGatheringAPI(
      {
        baseURL: env.WORK_DOTNET_API,
        token$: authStore.token$,
      },
      workRealtime$
    )

    this.jobRating = new JobRatingAPI({
      baseURL: env.WORK_DOTNET_API,
      token$: authStore.token$,
    })

    this.bookkeeping = new BookkeepingAPI(
      {
        baseURL: env.WORK_DOTNET_API,
        token$: authStore.token$,
      },
      workRealtime$
    )

    this.quickbooks = new QuickBooksPlatformConnectionAPI({
      baseURL: env.WORK_DOTNET_API,
      token$: authStore.token$,
    })

    this.xero = new XeroPlatformConnectionAPI({
      baseURL: env.WORK_DOTNET_API,
      token$: authStore.token$,
    })

    this.jobTaxAssistant = new JobTaxAssistantAPI(
      {
        baseURL: env.WORK_DOTNET_API,
        token$: authStore.token$,
      },
      workRealtime$
    )

    this.individualProfile = new IndividualProfileAPI({
      baseURL: env.WORK_DOTNET_API,
      token$: authStore.token$,
    })

    this.businessProfile = new BusinessProfileAPI({
      baseURL: env.WORK_DOTNET_API,
      token$: authStore.token$,
    })

    this.taxRefundMethod = new TaxRefundMethodAPI({
      baseURL: env.WORK_DOTNET_API,
      token$: authStore.token$,
    })
  }

  origin = window.location.origin

  get workAPIClient() {
    const client = this.client(env.JOB_API)
    client.interceptors.request.use((config) => {
      config.params ??= {}
      config.params.supported_engine_version = SUPPORTED_ENGINE_VERSION
      return config
    })
    return client
  }

  get localClient() {
    return this.client()
  }

  get user() {
    return this.authStore.user
  }

  client(baseUrl) {
    return axios.create({
      baseURL: baseUrl.startsWith('/')
        ? `${window.location.origin}${baseUrl}`
        : baseUrl,
      headers: {
        Authorization: `Bearer ${this.authStore.token}`,
      },
    })
  }
}

class APIClient {
  constructor(api) {
    this.api = api
  }

  on(eventName, handler) {
    this.api.authStore.onLogin(async () => {
      getFeathersEventManager(
        this.api.workAPIClient.defaults.baseURL,
        this.api.authStore.token,
        {
          supported_engine_version: SUPPORTED_ENGINE_VERSION,
        }
      ).subscribe(this.service, eventName, handler)
    })
  }
}

class ProviderAPI extends APIClient {
  service = 'job-providers'

  get(id) {
    return this.api.workAPIClient.get(`/job-providers/${id}`).then(_data)
  }

  find(params) {
    params = { ...params }
    return this.api.workAPIClient.get(`/job-providers`, { params }).then(_data)
  }

  patch(id, params) {
    params = { ...params, status: 'UNCLAIMED' }
    return this.api.workAPIClient
      .patch(`/job-providers/${id}`, params)
      .then(_data)
  }
}

class JobsAPI extends APIClient {
  service = 'jobs'

  get(id) {
    return this.api.workAPIClient.get(`/jobs/${id}`).then(_data)
  }

  find(params) {
    params = { $limit: 100, ...params }
    return this.api.workAPIClient.get('/jobs', { params }).then(_data)
  }

  create(data) {
    return this.api.workAPIClient.post('/jobs', data).then(_data)
  }

  patch(id, data) {
    return this.api.workAPIClient.patch(`/jobs/${id}`, data).then(_data)
  }

  submit(id) {
    return this.patch(id, { status: 'UNCLAIMED' }).then(_data)
  }

  delete(id) {
    return this.api.workAPIClient.delete(`/jobs/${id}`).then(_data)
  }

  price(id) {
    return this.api.workAPIClient.get(`/job-price/${id}`).then(_data)
  }

  resubmitJob(id) {
    return this.api.workAPIClient.post(`/job/${id}/duplicate-job`).then(_data)
  }

  providePaymentInfo(jobId, params) {
    return this.api.workAPIClient
      .post(`/jobs/${jobId}/payment-info`, params)
      .then(_data)
  }
}

class JobEventsAPI extends APIClient {
  service = 'job-events'

  find(query) {
    return this.api.workAPIClient
      .get('/job-events', { params: query })
      .then(_data)
  }
}

class JobEventsV3API {
  constructor(opts, events$) {
    this.client = createJobEventsGrpcClient(opts.baseURL)
    this.grpcAuthenticator = createGrpcAuthenticator({ token$: opts.token$ })

    this.events$ = events$.pipe(
      filter(
        (event) =>
          event.getTypeCase() === RealtimeEvent.TypeCase.JOB_EVENT_RECORDED
      )
    )
  }

  /**
   * Lists job events.
   *
   * @param {string} jobId
   * @param {number} limit
   * @param {string} after
   *
   * @returns {Promise<{ jobEventsList: JobEventDto[], nextPageToken: string }>}
   */
  async listJobEvents({ jobId, limit, after }) {
    const request = new ListJobEventsRequest()
      .setJobId(jobId)
      .setPageSize(limit)
      .setPageToken(after)

    const response = await this.client.listJobEvents(
      request,
      await this.grpcAuthenticator.getMeta()
    )

    return response.toObject()
  }
}

class LegendsAPI extends APIClient {
  get client() {
    return this.api.client(env.LEGEND_API)
  }

  find({ workspace_id, ...query }) {
    return this.client
      .get(`/v3/workspaces/${workspace_id}/legends`, { params: query })
      .then(_data)
  }

  get(id) {
    return this.client.get(`/v3/legends/${id}`).then(_data)
  }

  getSettings(payload) {
    return this.client
      .post(`/v3/legends/id-query/settings`, payload)
      .then(_data)
  }

  getVersion(id, version, params) {
    return this.client
      .get(`/v3/legends/${id}${version ? `/versions/${version}` : ''}`, {
        ...params,
      })
      .then(_data)
  }
}

const createConsultationGrpcClient = memoize((baseUrl) => {
  const client = new ConsultationServicePromiseClient(baseUrl)
  enableDevTools(client)
  return client
})

const createJobEventsGrpcClient = memoize((baseUrl) => {
  const client = new JobEventsServicePromiseClient(baseUrl)
  enableDevTools(client)
  return client
})

const createDiyGrpcClient = memoize((baseUrl) => {
  const client = new DiyServicePromiseClient(baseUrl)
  enableDevTools(client)
  return client
})

class DiyAPI {
  constructor(opts) {
    this.client = createDiyGrpcClient(opts.baseURL)
    this.grpcAuthenticator = createGrpcAuthenticator({ token$: opts.token$ })
  }

  async createDiyUrl({ workspaceId }) {
    const request = new CreateDiyUrlRequest().setWorkspaceId(workspaceId)
    const response = await this.client.createDiyUrl(
      request,
      await this.grpcAuthenticator.getMeta()
    )
    return response.toObject()
  }

  async linkConsultationJobToDiyJob({ diyJobId, consultationJobId }) {
    const request = new LinkConsultationJobToDiyJobRequest()
      .setDiyJobId(diyJobId)
      .setConsultationJobId(consultationJobId)

    const response = await this.client.linkConsultationJobToDiyJob(
      request,
      await this.grpcAuthenticator.getMeta()
    )
    return response.toObject()
  }

  async checkTaxYearForDiyJob({ diyJobId }) {
    const request = new CheckTaxYearRequest().setDiyJobId(diyJobId)

    const response = await this.client.checkTaxYear(
      request,
      await this.grpcAuthenticator.getMeta()
    )
    return response.toObject()
  }

  async checkDiyEnabled({ workspaceId }) {
    const request = new CheckDiyEnabledRequest().setWorkspaceId(workspaceId)

    const response = await this.client.checkDiyEnabled(
      request,
      await this.grpcAuthenticator.getMeta()
    )
    return response.toObject()
  }

  async getCurrentYearDiyJob({ workspaceId }) {
    const request = new GetCurrentYearDiyJobRequest().setWorkspaceId(
      workspaceId
    )

    const response = await this.client.getCurrentYearDiyJob(
      request,
      await this.grpcAuthenticator.getMeta()
    )

    return mapGrpcDiyJob(response.toObject()?.currentYearDiyJob?.diyJob)
  }

  async hideDiyJob({ diyJobId, workspaceId }) {
    const request = new HideDiyJobRequest()
    request.setWorkspaceId(workspaceId)
    request.setDiyJobId(diyJobId)

    await this.client.hideDiyJob(
      request,
      await this.grpcAuthenticator.getMeta()
    )
  }
}

class ConsultationAPI {
  constructor(opts) {
    this.client = createConsultationGrpcClient(opts.baseURL)
    this.grpcAuthenticator = createGrpcAuthenticator({ token$: opts.token$ })
  }

  async setConsultationAvailability({ jobId, availability }) {
    const availabilityIntervals = availability.map((a) =>
      new Interval()
        .setStartTime(Timestamp.fromDate(a.startTime))
        .setEndTime(Timestamp.fromDate(a.endTime))
    )
    const request = new SetConsultationAvailabilityRequest()
      .setJobId(jobId)
      .setAvailabilityList(availabilityIntervals)

    const response = await this.client.setConsultationAvailability(
      request,
      await this.grpcAuthenticator.getMeta()
    )

    return response.toObject()
  }

  async downloadConsultationCalendar({ jobId }) {
    return await this.client
      .downloadConsultationCalendar(
        new DownloadConsultationCalendarRequest().setId(jobId),
        await this.grpcAuthenticator.getMeta()
      )
      .then(
        (resp) =>
          new File([resp.getData_asU8()], 'calendar.ics', {
            type: 'text/calendar',
          })
      )
  }

  /**
   * Given the ID of a pro, searches for that pro's booked time ranges.
   */
  async listBookedTimeRangesForPro(proId, jobId) {
    return await this.client.listBookedTimeRangesForPro(
      new ListBookedTimeRangesForProRequest().setProId(proId).setJobId(jobId),
      await this.grpcAuthenticator.getMeta()
    )
  }
}

const createInfoGatheringGrpcClient = memoize((baseUrl) => {
  const client = new InfoGatheringServicePromiseClient(baseUrl)
  enableDevTools(client)
  return client
})

class InfoGatheringAPI {
  constructor(opts, events$) {
    this.client = createInfoGatheringGrpcClient(opts.baseURL)
    this.grpcAuthenticator = createGrpcAuthenticator({ token$: opts.token$ })
    this.events$ = events$.pipe(
      filter((event) => {
        switch (event.getTypeCase()) {
          case RealtimeEvent.TypeCase.REQUESTED_SUPPORTING_ITEM_UPDATED:
          case RealtimeEvent.TypeCase.DOCUMENT_REQUEST_CREATED:
            return true
        }

        return false
      })
    )
  }

  async getChecklist({ jobId }) {
    const request = new GetChecklistRequest().setJobId(jobId)

    const response = await this.client.getChecklist(
      request,
      await this.grpcAuthenticator.getMeta()
    )
    return response.toObject()
  }

  async provideSupportingItemDocument({ jobId, supportingItemId, documentId }) {
    const request = new ProvideSupportingItemDocumentRequest()
      .setId(supportingItemId)
      .setJobId(jobId)
      .setDocumentId(documentId)

    const response = await this.client.provideSupportingItemDocument(
      request,
      await this.grpcAuthenticator.getMeta()
    )

    return response.toObject()
  }

  async removeSupportingItemDocument({ supportingItemId, documentId }) {
    const request = new RemoveSupportingItemDocumentRequest()
      .setId(supportingItemId)
      .setDocumentId(documentId)

    const response = await this.client.removeSupportingItemDocument(
      request,
      await this.grpcAuthenticator.getMeta()
    )

    return response.toObject()
  }

  async provideSupportingItemPersonalInfo({
    jobId,
    supportingItemId,
    profileId,
    profileVersion,
  }) {
    const request = new ProvideSupportingItemPersonalInfoRequest()
      .setId(supportingItemId)
      .setJobId(jobId)
      .setProfileId(profileId)
      .setProfileVersion(profileVersion)

    const response = await this.client.provideSupportingItemPersonalInfo(
      request,
      await this.grpcAuthenticator.getMeta()
    )

    return response.toObject()
  }

  async provideSupportingItemPersonalRefundMethod({
    refundMethodId,
    refundMethodVersion,
    supportingItemId,
    jobId,
  }) {
    const request = new ProvideSupportingItemPersonalRefundMethodRequest()
      .setId(supportingItemId)
      .setJobId(jobId)
      .setRefundMethodId(refundMethodId)
      .setRefundMethodVersion(refundMethodVersion)

    const response =
      await this.client.provideSupportingItemPersonalRefundMethod(
        request,
        await this.grpcAuthenticator.getMeta()
      )

    return response.toObject()
  }

  async provideSupportingItemBusinessRefundMethod({
    refundMethodId,
    refundMethodVersion,
    supportingItemId,
    jobId,
  }) {
    const request = new ProvideSupportingItemBusinessRefundMethodRequest()
      .setId(supportingItemId)
      .setJobId(jobId)
      .setRefundMethodId(refundMethodId)
      .setRefundMethodVersion(refundMethodVersion)

    const response =
      await this.client.provideSupportingItemBusinessRefundMethod(
        request,
        await this.grpcAuthenticator.getMeta()
      )

    return response.toObject()
  }

  async provideSupportingItemBusinessInfo({
    jobId,
    supportingItemId,
    profileId,
    profileVersion,
  }) {
    const request = new ProvideSupportingItemBusinessInfoRequest()
      .setId(supportingItemId)
      .setJobId(jobId)
      .setProfileId(profileId)
      .setProfileVersion(profileVersion)

    return this.client
      .provideSupportingItemBusinessInfo(
        request,
        await this.grpcAuthenticator.getMeta()
      )
      .then((res) => res.toObject())
  }

  async provideSupportingItemSpousalInfo({
    jobId,
    supportingItemId,
    profileId,
    profileVersion,
  }) {
    const request = new ProvideSupportingItemSpousalInfoRequest()
      .setId(supportingItemId)
      .setJobId(jobId)
      .setProfileId(profileId)
      .setProfileVersion(profileVersion)

    const response = await this.client.provideSupportingItemSpousalInfo(
      request,
      await this.grpcAuthenticator.getMeta()
    )

    return response.toObject()
  }

  async provideSupportingItemDependentInfo({
    jobId,
    supportingItemId,
    profileId,
    profileVersion,
  }) {
    const request = new ProvideSupportingItemDependentInfoRequest()
      .setId(supportingItemId)
      .setJobId(jobId)
      .setProfileId(profileId)
      .setProfileVersion(profileVersion)

    const response = await this.client.provideSupportingItemDependentInfo(
      request,
      await this.grpcAuthenticator.getMeta()
    )

    return response.toObject()
  }

  async removeSupportingItemDependentInfo({
    jobId,
    supportingItemId,
    profileId,
  }) {
    const request = new RemoveSupportingItemDependentInfoRequest()
      .setId(supportingItemId)
      .setJobId(jobId)
      .setProfileId(profileId)

    const response = await this.client.removeSupportingItemDependentInfo(
      request,
      await this.grpcAuthenticator.getMeta()
    )

    return response.toObject()
  }
}

const createJobRatingGrpcClient = memoize((baseUrl) => {
  const client = new JobRatingServicePromiseClient(baseUrl)
  enableDevTools(client)
  return client
})

class JobRatingAPI {
  constructor(opts) {
    this.client = createJobRatingGrpcClient(opts.baseURL)
    this.grpcAuthenticator = createGrpcAuthenticator({ token$: opts.token$ })
  }

  async getJobRatingStatus({ jobId }) {
    const request = new GetJobRatingStatusRequest().setJobId(jobId)
    const response = await this.client.getJobRatingStatus(
      request,
      await this.grpcAuthenticator.getMeta()
    )

    return response.toObject()
  }

  async submitProRating({ jobId, rating, comment, proUserPublicId }) {
    const request = new SubmitProRatingRequest()
      .setJobId(jobId)
      .setRating(rating)
      .setComment(comment)
      .setProviderUserPublicId(proUserPublicId)
    const response = await this.client.submitProRating(
      request,
      await this.grpcAuthenticator.getMeta()
    )

    return response.toObject()
  }

  async submitPlatformRating({ jobId, rating, comment, proUserPublicId }) {
    const request = new SubmitPlatformRatingRequest()
      .setJobId(jobId)
      .setRating(rating)
      .setComment(comment)
      .setProviderUserPublicId(proUserPublicId)
    const response = await this.client.submitPlatformRating(
      request,
      await this.grpcAuthenticator.getMeta()
    )

    return response.toObject()
  }
}

class DocumentTypeAPI extends APIClient {
  get client() {
    return this.api.client(env.DOCUMENT_API)
  }

  getMany(params) {
    return this.client.post('/v3/types/id-query', params).then(_data)
  }

  fetchDocumentTypes(params) {
    return this.client
      .get('/v4/types', { params })
      .then((response) => _data(response).data)
  }
}

function _data(response) {
  return response.data
}

const createQuickBooksClient = (baseUrl) => {
  const client = new QuickBooksPlatformConnectionServicePromiseClient(baseUrl)
  enableDevTools(client)
  return client
}

class QuickBooksPlatformConnectionAPI {
  constructor(opts, events$) {
    this.client = createQuickBooksClient(opts.baseURL)
    this.grpcAuthenticator = createGrpcAuthenticator({ token$: opts.token$ })
  }

  /**
   * Lists all QuickBooks connections.
   *
   * @param {*} params
   * @returns
   */
  async listQuickBooksConnections(params) {
    const req = new ListConnectedQuickBooksAccountsRequest()
    if (params.after) {
      req.setPageToken(params.after)
    }
    if (params.limit) {
      req.setPageSize(params.limit)
    }

    const result = await this.client.listConnectedQuickBooksAccounts(
      req,
      await this.grpcAuthenticator.getMeta()
    )

    const accountsList = result
      .getConnectedQuickbooksAccountsList()
      .map((p) => p.toObject())

    return {
      data: accountsList ?? [],
      cursor: result.getNextPageToken(),
    }
  }

  /**
   * Manually creates a QuickBooks connection.
   *
   * @param {*} params
   * @returns
   */
  async createQuickBooksPlatformConnection(realmId, companyName, workspaceId) {
    const payload = new CreateQuickBooksPlatformConnectionRequest()
      .setRealmId(realmId)
      .setCompanyName(companyName)
      .setWorkspaceId(workspaceId)

    return this.client.createQuickBooksPlatformConnection(
      payload,
      await this.grpcAuthenticator.getMeta()
    )
  }

  /**
   * Creates a QuickBooks platform connection from an oauth2 code
   * @param {string} code
   * @param {string} realmId
   * @param {string} redirectUri
   * @param {string} workspaceId
   * @returns {{ id: string, updateTime: number }}
   */
  async createQuickBooksPlatformConnectionFromOAuth2Code(
    code,
    realmId,
    redirectUri,
    workspaceId
  ) {
    const payload =
      new CreateQuickBooksPlatformConnectionFromOAuth2CodeRequest()
        .setRealmId(realmId)
        .setCode(code)
        .setRedirectUri(redirectUri)
        .setWorkspaceId(workspaceId)

    return this.client.createQuickBooksPlatformConnectionFromOAuth2Code(
      payload,
      await this.grpcAuthenticator.getMeta()
    )
  }
}

const createXeroClient = memoize((baseUrl) => {
  const client = new XeroPlatformConnectionServicePromiseClient(baseUrl)
  enableDevTools(client)
  return client
})

class XeroPlatformConnectionAPI {
  constructor(opts, events$) {
    this.client = createXeroClient(opts.baseURL)
    this.grpcAuthenticator = createGrpcAuthenticator({ token$: opts.token$ })
  }

  /**
   * Lists all Xero connections.
   *
   * @param {*} params
   * @returns
   */
  async listXeroConnections(params) {
    const req = new ListConnectedXeroAccountsRequest()

    if (params.after) {
      req.setPageToken(params.after)
    }
    if (params.limit) {
      req.setPageSize(params.limit)
    }

    const result = await this.client.listConnectedXeroAccounts(
      req,
      await this.grpcAuthenticator.getMeta()
    )
    const accountsList = result
      .getConnectedXeroAccountsList()
      .map((p) => p.toObject())

    return {
      data: accountsList,
      cursor: result.getNextPageToken(),
    }
  }

  /**
   * Creates a Xero platform connection from an oauth2 code
   * @param {string} code
   * @param {string} redirectUri
   * @param {string} workspaceId
   * @returns {{ id: string, updateTime: number }}
   */
  async createXeroPlatformConnectionFromOAuth2Code(
    code,
    redirectUri,
    workspaceId
  ) {
    const payload = new CreateXeroPlatformConnectionFromOAuth2CodeRequest()
      .setCode(code)
      .setRedirectUri(redirectUri)
      .setWorkspaceId(workspaceId)

    return this.client.createXeroPlatformConnectionFromOAuth2Code(
      payload,
      await this.grpcAuthenticator.getMeta()
    )
  }
}

const createBookkepingClient = memoize((baseUrl) => {
  const client = new BookkeepingServicePromiseClient(baseUrl)
  enableDevTools(client)
  return client
})

class BookkeepingAPI {
  constructor(opts, events$) {
    this.client = createBookkepingClient(opts.baseURL)
    this.grpcAuthenticator = createGrpcAuthenticator({ token$: opts.token$ })
    this.events$ = events$.pipe(
      filter((event) => {
        switch (event.getTypeCase()) {
          case RealtimeEvent.TypeCase.BOOKKEEPING_UPDATED:
            return true
        }

        return false
      })
    )
  }

  /**
   * Gets the bookkeeping progression info for a job.
   *
   * @param {*} params
   * @returns
   */
  async getBookkeepingProgressionInfo(id) {
    const req = new GetBookkeepingProgressionInfoRequest().setId(id)

    return this.client
      .getBookkeepingProgressionInfo(
        req,
        await this.grpcAuthenticator.getMeta()
      )
      .then((res) => res.toObject())
  }

  /**
   * Provies platform connection.
   *
   * @param {string} jobId
   * @param {{connectionId: string, platform: string }} options
   * @returns {Promise<{id: string}}
   */
  async providePlatformConnection(jobId, options) {
    const payload = new ProvidePlatformConnectionRequest().setId(jobId)

    switch (options.platform) {
      case 'QuickBooks':
        payload.setQuickBooks(
          new QuickBooksPlatformConnection().setConnectionId(
            options.connectionId
          )
        )
        break
      case 'Xero':
        payload.setXero(
          new XeroPlatformConnection().setConnectionId(options.connectionId)
        )
        break
      case 'Other':
        payload.setOther(new OtherPlatformConnection().setInfo(options.info))
        break
      default:
        throw new Error(`The platform ${options.platform} is not supported.`)
    }

    return this.client
      .providePlatformConnection(
        payload,
        await this.grpcAuthenticator.getMeta()
      )
      .then((res) => res.toObject())
  }

  /**
   * Marks access as granted for the given bookkeeping tracker ID.
   *
   * @param {string} id - the bookkeeping tracker ID.
   * @returns {Promise<{id: string}>}
   */
  async markAccessAsGranted(id) {
    const payload = new MarkAccessAsGrantedRequest().setId(id)

    return this.client
      .markAccessAsGranted(payload, await this.grpcAuthenticator.getMeta())
      .then((res) => res.toObject())
  }
}

const createJobTaxAssistantClient = memoize((baseUrl) => {
  const client = new JobTaxAssistantServicePromiseClient(baseUrl)
  enableDevTools(client)
  return client
})

/**
 * Creates an instance of the JobTaxAssistant API.
 *
 * @param config
 */
export class JobTaxAssistantAPI {
  constructor(opts, events$) {
    this.client = createJobTaxAssistantClient(opts.baseURL)
    this.grpcAuthenticator = createGrpcAuthenticator({ token$: opts.token$ })
    this.events$ = events$.pipe(
      filter(
        (e) =>
          e.getTypeCase() === RealtimeEvent.TypeCase.JOB_TAX_ASSISTANT_UPDATED
      )
    )
  }

  async get(request) {
    return this.client.getJobTaxAssistant(
      request,
      await this.grpcAuthenticator.getMeta()
    )
  }
}

const createIndividualProfileGrpcClient = memoize((baseUrl) => {
  const client = new IndividualProfileServicePromiseClient(baseUrl)
  enableDevTools(client)
  return client
})

class IndividualProfileAPI {
  constructor(opts) {
    this.client = createIndividualProfileGrpcClient(opts.baseURL)
    this.grpcAuthenticator = createGrpcAuthenticator({ token$: opts.token$ })
  }

  // Personal Profile

  async getPersonalProfile(workspaceId) {
    const request = new GetPersonalProfileRequest().setWorkspaceId(workspaceId)

    const response = await this.client.getPersonalProfile(
      request,
      await this.grpcAuthenticator.getMeta()
    )

    return response.toObject()
  }

  async getPersonalProfileForJob(jobId) {
    const request = new GetPersonalProfileForJobRequest().setJobId(jobId)

    const response = await this.client.getPersonalProfileForJob(
      request,
      await this.grpcAuthenticator.getMeta()
    )

    return response.toObject()
  }

  async savePersonalProfile(
    workspaceId,
    personalInfo,
    contactInfo,
    occupation
  ) {
    const request = new SavePersonalProfileRequest()
      .setWorkspaceId(workspaceId)
      .setPersonalInfo(personalInfoToProto(personalInfo))
      .setOccupation(occupation)
      .setContactInfo(contactInfoToProto(contactInfo))

    const response = await this.client.savePersonalProfile(
      request,
      await this.grpcAuthenticator.getMeta()
    )

    return response.toObject()
  }

  // Spousal Profile

  async getSpousalProfile(workspaceId) {
    const request = new GetSpousalProfileRequest().setWorkspaceId(workspaceId)

    const response = await this.client.getSpousalProfile(
      request,
      await this.grpcAuthenticator.getMeta()
    )

    return response.toObject()
  }

  async getSpousalProfileForJob(jobId) {
    const request = new GetSpousalProfileForJobRequest().setJobId(jobId)

    const response = await this.client.getSpousalProfileForJob(
      request,
      await this.grpcAuthenticator.getMeta()
    )

    return response.toObject()
  }

  async saveSpousalProfile(workspaceId, personalInfo, occupation) {
    const request = new SaveSpousalProfileRequest()
      .setWorkspaceId(workspaceId)
      .setPersonalInfo(personalInfoToProto(personalInfo))
      .setOccupation(occupation)

    const response = await this.client.saveSpousalProfile(
      request,
      await this.grpcAuthenticator.getMeta()
    )

    return response.toObject()
  }

  // Dependent Profile

  async getDependentProfile(profileId) {
    const request = new GetDependentProfileRequest().setId(profileId)

    const response = await this.client.getDependentProfile(
      request,
      await this.grpcAuthenticator.getMeta()
    )

    return response.toObject()
  }

  async createDependentProfile(workspaceId, personalInfo, relationship) {
    const request = new CreateDependentProfileRequest()
      .setWorkspaceId(workspaceId)
      .setPersonalInfo(personalInfoToProto(personalInfo))
      .setRelationship(relationship)

    const response = await this.client.createDependentProfile(
      request,
      await this.grpcAuthenticator.getMeta()
    )

    return response.toObject()
  }

  async updateDependentProfile(profileId, personalInfo, relationship) {
    const request = new UpdateDependentProfileRequest()
      .setId(profileId)
      .setPersonalInfo(personalInfoToProto(personalInfo))
      .setRelationship(relationship)

    const response = await this.client.updateDependentProfile(
      request,
      await this.grpcAuthenticator.getMeta()
    )

    return response.toObject()
  }
}

const createBusinessProfileGrpcClient = memoize((baseUrl) => {
  const client = new BusinessProfileServicePromiseClient(baseUrl)
  enableDevTools(client)
  return client
})

class BusinessProfileAPI {
  constructor(opts) {
    this.client = createBusinessProfileGrpcClient(opts.baseURL)
    this.grpcAuthenticator = createGrpcAuthenticator({ token$: opts.token$ })
  }

  async getBusinessProfile(id) {
    const request = new GetBusinessProfileRequest().setId(id)
    const meta = await this.grpcAuthenticator.getMeta()

    return this.client
      .getBusinessProfile(request, meta)
      .then((r) => r.toObject())
  }

  async getBusinessProfileForJob(jobId) {
    const request = new GetBusinessProfileForJobRequest().setJobId(jobId)
    const meta = await this.grpcAuthenticator.getMeta()

    return this.client
      .getBusinessProfileForJob(request, meta)
      .then((r) => r.toObject())
  }

  async createBusinessProfile(workspaceId, businessInfo, contactInfo) {
    const meta = await this.grpcAuthenticator.getMeta()
    const request = new CreateBusinessProfileRequest()
      .setWorkspaceId(workspaceId)
      .setBusinessInfo(businessInfoToProto(businessInfo))
      .setContactInfo(contactInfoToProto(contactInfo))

    return this.client
      .createBusinessProfile(request, meta)
      .then((r) => r.toObject())
  }

  async updateBusinessProfile(id, businessInfo, contactInfo) {
    const meta = await this.grpcAuthenticator.getMeta()
    const request = new UpdateBusinessProfileRequest()
      .setId(id)
      .setBusinessInfo(businessInfoToProto(businessInfo))
      .setContactInfo(contactInfoToProto(contactInfo))

    return this.client
      .updateBusinessProfile(request, meta)
      .then((r) => r.toObject())
  }
}

const createTaxRefundMethodGrpcClient = memoize((baseUrl) => {
  const client = new TaxRefundMethodServicePromiseClient(baseUrl)
  enableDevTools(client)
  return client
})

class TaxRefundMethodAPI {
  constructor(opts) {
    this.client = createTaxRefundMethodGrpcClient(opts.baseURL)
    this.grpcAuthenticator = createGrpcAuthenticator({ token$: opts.token$ })
  }

  async getTaxRefundMethod(refundMethodId) {
    const request = new GetTaxRefundMethodRequest().setId(refundMethodId)
    const meta = await this.grpcAuthenticator.getMeta()

    return this.client
      .getTaxRefundMethod(request, meta)
      .then((r) => r.toObject())
  }

  async getPersonalTaxRefundMethodForJob(jobId) {
    const request = new GetPersonalTaxRefundMethodForJobRequest().setJobId(
      jobId
    )
    const meta = await this.grpcAuthenticator.getMeta()

    return this.client
      .getPersonalTaxRefundMethodForJob(request, meta)
      .then((r) => r.toObject())
  }

  async getBusinessTaxRefundMethodForJob(jobId) {
    const request = new GetBusinessTaxRefundMethodForJobRequest().setJobId(
      jobId
    )
    const meta = await this.grpcAuthenticator.getMeta()

    return this.client
      .getBusinessTaxRefundMethodForJob(request, meta)
      .then((r) => r.toObject())
  }

  async createPersonalTaxRefundMethod(workspaceId, deliveryMethodDto) {
    const request = new CreateTaxRefundMethodRequest()
      .setWorkspaceId(workspaceId)
      .setRefundType(TaxRefundType.PERSONAL)
      .setDeliveryMethod(deliveryMethodToProto(deliveryMethodDto))
    const meta = await this.grpcAuthenticator.getMeta()

    return this.client
      .createTaxRefundMethod(request, meta)
      .then((r) => r.toObject())
  }

  async createBusinessTaxRefundMethod(workspaceId, deliveryMethodDto) {
    const request = new CreateTaxRefundMethodRequest()
      .setWorkspaceId(workspaceId)
      .setRefundType(TaxRefundType.BUSINESS)
      .setDeliveryMethod(deliveryMethodToProto(deliveryMethodDto))
    const meta = await this.grpcAuthenticator.getMeta()

    return this.client
      .createTaxRefundMethod(request, meta)
      .then((r) => r.toObject())
  }

  async updateTaxRefundMethod(id, deliveryMethodDto) {
    const request = new UpdateTaxRefundMethodRequest()
      .setId(id)
      .setDeliveryMethod(deliveryMethodToProto(deliveryMethodDto))
    const meta = await this.grpcAuthenticator.getMeta()

    return this.client
      .updateTaxRefundMethod(request, meta)
      .then((r) => r.toObject())
  }
}
