import { observable, computed, action } from 'mobx'
import links from 'src/client/misc/links'
import { browserHistory } from 'react-router'
import QS from 'qs'
import { translate as T } from '@taxfyle/web-commons/lib/utils/translate'

export default class WizardCompletedState {
  constructor({ wizard }) {
    this.wizard = wizard
    this.hideBackButton = true
  }

  stepId = 'WizardCompleted'
  workspaceName =
    (window.__workspaceDomain &&
      window.__workspaceDomain.workspace &&
      window.__workspaceDomain.workspace.name) ||
    'Worklayer'

  title = `${T(
    'Web.Onboarding.Completed.Title',
    `Thank you for using ${this.workspaceName}!`
  )}!`

  trackingId = 'Onboarding - Payment Success'

  @observable
  showingReferrerModal = false

  @computed
  get member() {
    return this.wizard.rootStore.sessionStore.member
  }

  @computed
  get workspace() {
    return this.wizard.rootStore.sessionStore.workspace
  }

  @computed
  get showPersonalFilingTip() {
    const legend = this.wizard.getStep('ProjectType').legend
    if (!legend) {
      return false
    }

    return legend.id === 'BUSINESS_TAX_RETURN'
  }

  @computed
  get job() {
    return this.wizard.job
  }

  @action.bound
  toggleReferrerModal() {
    this.showingReferrerModal = !this.showingReferrerModal
  }

  @action.bound
  showEmailDialog() {
    this.wizard.rootStore.changeEmailDialogStore.show()
  }

  @action.bound
  exit() {
    // We'll queue up the job tax assistant greeting only as a direct result of
    // submitting a job **once**.
    this.wizard.rootStore.jobTaxAssistantGreetingDialogStore.queueForJob(
      this.job.id
    )

    const qs = QS.parse(location.search, { ignoreQueryPrefix: true })
    const diyConfig =
      this.wizard.rootStore.sessionStore.workspace.config.diy || {}

    if (!diyConfig.upsell_consultations) {
      browserHistory.replace(links.projectDetails(this.job.id, qs?.hideNavbar))
      return
    }

    // If the legend on the job is the DIY upsell consultation, then the core service is DIY.
    if (this.job.legendId === diyConfig.consultation_legend_id) {
      browserHistory.replace(
        links.diy({
          consultationJobId: this.job.id,
          hideNavbar: qs?.hideNavbar,
        })
      )
      return
    }
    browserHistory.replace(links.projectDetails(this.job.id, qs?.hideNavbar))
  }
}
