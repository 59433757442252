import { translate as T } from '@taxfyle/web-commons/lib/utils/translate'
import memoize from 'memoizee'
import { computed, when, action, runInAction } from 'mobx'
import { task } from 'mobx-task'
import links from 'misc/links'
import { browserHistory } from 'react-router'

export default class TaxProjectServiceTypeState {
  stepId = 'TaxProjectServiceType'
  title = T(
    'Web.Onboarding.TaxLegendServiceTypeSelect.Header',
    'How would you like to file your taxes?'
  )

  description = T(
    'Web.Onboarding.TaxLegendServiceTypeSelect.Description',
    'Choose the level of support that works best for your situation'
  )

  diyTitle = T(
    'Web.Onboarding.TaxLegendServiceTypeSelect.Diy.Title',
    'Self-Service'
  )

  diyDescription = T(
    'Web.Onboarding.TaxLegendServiceTypeSelect.Diy.Description',
    'File your taxes on your own using our easy-to-use self-service platform.'
  )

  diySubtext = T('Web.Onboarding.TaxLegendServiceTypeSelect.Diy.Subtext', '')

  assistedFilingTitle = T(
    'Web.Onboarding.TaxLegendServiceTypeSelect.AssistedFiling.Title',
    'Pro Assist'
  )

  assistedFilingDescription = T(
    'Web.Onboarding.TaxLegendServiceTypeSelect.AssistedFiling.Description',
    'File your taxes on your own but with extra support. Consult a Tax Pro if you need guidance along the way.'
  )

  assistedFilingSubtext = T(
    'Web.Onboarding.TaxLegendServiceTypeSelect.AssistedFiling.Subtext',
    ''
  )

  fullServiceTitle = T(
    'Web.Onboarding.TaxLegendServiceTypeSelect.FullService.Title',
    'Full-Service'
  )

  fullServiceDescription = T(
    'Web.Onboarding.TaxLegendServiceTypeSelect.FullService.Description',
    'Relax while a Tax Pro handles your return from start to finish.'
  )

  fullServiceSubtext = T(
    'Web.Onboarding.TaxLegendServiceTypeSelect.FullService.Subtext',
    ''
  )

  trackingId = 'Onboarding - Tax Project Service Type Selection'

  constructor({ wizard }) {
    this.wizard = wizard
    this.rootStore = wizard.rootStore
    this.activate = this.activate.wrap((fn) =>
      memoize(fn, { promise: true, length: 0 })
    )
    this.rootStore.sessionStore.onWorkspaceSelected(() => this.activate.clear())
  }

  @computed
  get relevant() {
    return (
      this.wizard.diyEnabled &&
      this.wizard.job === null &&
      this.wizard.getStep('ProjectServiceType').legendServiceType === 'TAX'
    )
  }

  @computed
  get loading() {
    return this.goToAssistedFiling.pending
  }

  @computed
  get canUpsellConsultation() {
    const diyUpsellConsultations =
      this.rootStore.sessionStore.workspace.config.diy?.upsell_consultations
    const diyConsultationLegendId =
      this.rootStore.sessionStore.workspace.config.diy?.consultation_legend_id

    return Boolean(diyUpsellConsultations && diyConsultationLegendId)
  }

  @computed
  get currentYearDiyJob() {
    return this.wizard.currentYearDiyJob
  }

  @computed
  get diyJobId() {
    return this.currentYearDiyJob?.id
  }

  @computed
  get hasCurrentYearDiyConsultationJob() {
    return Boolean(this.currentYearDiyJob?.consultationJobId !== undefined)
  }

  @computed
  get canGoToDiy() {
    return this.rootStore.sessionStore.member.hasPermission('DIY_CREATE')
  }

  @task
  async activate() {
    await when(() => this.rootStore.sessionStore.workspace)
  }

  @action.bound
  goToFullService() {
    this.wizard.next()
  }

  @task.resolved
  async goToAssistedFiling() {
    if (!this.canUpsellConsultation) {
      return
    }

    const msg = this.rootStore.flashMessageStore.create({
      message: 'Please wait...',
      inProgress: true,
    })

    const diyConsultationLegendId =
      this.rootStore.sessionStore.workspace.config.diy?.consultation_legend_id

    try {
      const latestConsultationLegendVersion =
        await this.rootStore.api.legends.getVersion(diyConsultationLegendId)

      const consultationJobId = await this.rootStore.draftStore.createDraftJob(
        diyConsultationLegendId,
        latestConsultationLegendVersion.version
      )

      if (this.currentYearDiyJob) {
        this.rootStore.api.diy.linkConsultationJobToDiyJob({
          diyJobId: this.diyJobId,
          consultationJobId: consultationJobId,
        })
      } else {
        const workspaceId = this.rootStore.sessionStore.workspace.id
        await this.rootStore.api.diy
          .createDiyUrl({ workspaceId })
          .then((res) => {
            this.rootStore.api.diy.linkConsultationJobToDiyJob({
              diyJobId: res.diyJobId,
              consultationJobId: consultationJobId,
            })
          })
      }

      msg.done('All done!')

      runInAction(() => {
        window.location = links.projectEditor(
          consultationJobId,
          this.wizard.qs?.hideNavbar,
          true
        )
      })
    } catch (error) {
      console.error('Error creating diy consultation job', error)
      msg.failed('An error occurred. Please try again.')
    }
  }

  @action.bound
  async goToDiy() {
    browserHistory.push(links.diy({ hideNavbar: this.wizard.qs?.hideNavbar }))
  }
}
