import cx from 'classnames'
import { BodyClass } from 'components/ClassName'
import FlashMessages from 'flash-messages/components/FlashMessages'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import styles from './LandingPage.m.sass'
import Button from 'components/Button'
import storeLinks from 'misc/storeLinks'
import { translate as T } from '@taxfyle/web-commons/lib/utils/translate'
import { markdownToHtml } from '../../utils/markdown'

const IOS_APP = storeLinks().taxfyle.appStore
const ANDROID_APP = storeLinks().taxfyle.playStore
const IOS_APP_WORKLAYER = storeLinks().worklayer.appStore
const ANDROID_APP_WORKLAYER = storeLinks().worklayer.playStore

function hasInviteOnlyOption(rules) {
  for (const rule of rules) {
    if (rule.type === 'InviteOnly') {
      return true
    }
    if (rule.type === 'AndOr') {
      return hasInviteOnlyOption(rule.rules)
    }
  }
  return false
}

export default observer(function LandingPage({
  onSignupClick,
  onLoginClick,
  trackUrlConversion,
  rootStore,
  fromInvite = false,
}) {
  const qs = new URLSearchParams(location.search)

  useEffect(() => {
    if (
      qs.get('error_description') &&
      qs.get('error_description') === 'UNABLE_TO_LINK'
    ) {
      rootStore.flashMessageStore.create({
        type: 'error',
        message:
          'We were unable to link your accounts. Please contact support for help',
      })
    }
  }, [])

  // HACK! Easiest way to get this done right now, does NOT mean
  // I am proud of it.

  const workspace = (window.__workspaceDomain &&
    window.__workspaceDomain.workspace) || {
    branding: { logos: {}, colors: {}, landing_page: {}, corporate: {} },
    config: { signup: { rules: [] } },
  }

  const branded = {
    id: workspace.id || undefined,
    name: workspace.name || 'Worklayer',
    hideSignup: hasInviteOnlyOption(workspace.config?.signup?.rules || []),
    corporate: workspace.branding.corporate.enabled || false,
    logo:
      workspace.branding.logos.primary ||
      require('./img/logo-worklayer-blue.png'),
    icon: workspace.branding.logos.icon || require('./img/logo-icon.png'),
    taxfyle: require('assets/img/taxfyle-logo.png'),
    image:
      workspace.branding.landing_page.image ||
      'https://storage.googleapis.com/worklayer-assets/worklayer-homepage/img_login-worklayer1.svg',
  }

  const taxfyle = Boolean(branded.id === '1')

  const authError = JSON.parse(window.localStorage.getItem('authError') || '{}')

  if (authError.description) {
    rootStore.flashMessageStore.create({
      type: 'error',
      message: authError.description,
    })
    window.localStorage.removeItem('authError')
  }
  return (
    <div className={cx(styles.root)}>
      <FlashMessages
        store={rootStore.flashMessageStore}
        position="bottomLeft"
      />
      <Helmet title={branded.name} />
      <BodyClass className={styles.body} />
      <CardView>
        <div className={styles.cardContent}>
          <div className={styles.cardItem}>
            <img alt="Worklayer" src={branded.logo} />
          </div>

          <div className={cx(styles.cardItem, styles.center)}>
            <div className={styles.title}>
              {fromInvite
                ? T('Web.LandingPage.Title.Invite', 'Welcome.')
                : T('Web.LandingPage.Title', 'Work. Simplified.')}
            </div>
            <div className={styles.description}>
              {fromInvite
                ? T(
                    'Web.LandingPage.Description.Invite',
                    `You have been invited to join ${branded.name}. ${
                      !branded.hideSignup
                        ? 'If you already have an account please click Sign In, otherwise click Sign Up to create a new account.'
                        : ''
                    }`
                  )
                : T(
                    'Web.LandingPage.Description',
                    'Worklayer aims to simplify the way that work gets done by routing the right jobs to the right people... every time.'
                  )}
            </div>
            <Button
              onClick={onLoginClick}
              className={styles.login}
              data-test="LoginButton"
            >
              Sign In
            </Button>
            {!branded.hideSignup && (
              <Button onClick={onSignupClick} className={styles.signup}>
                Sign Up
              </Button>
            )}
            {!branded.corporate &&
              workspace.classification !== 'B2B_HYBRID' &&
              workspace.classification !== 'B2B2C' && (
                <div className={styles.apps}>
                  Get the app on{' '}
                  <a
                    onClick={() =>
                      trackUrlConversion('iOS' + taxfyle ? '' : ' - Worklayer')
                    }
                    href={taxfyle ? IOS_APP : IOS_APP_WORKLAYER}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    iOS
                  </a>
                  ,{' '}
                  <a
                    onClick={() =>
                      trackUrlConversion(
                        'Android' + taxfyle ? '' : ' - Worklayer'
                      )
                    }
                    href={taxfyle ? ANDROID_APP : ANDROID_APP_WORKLAYER}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Android
                  </a>
                </div>
              )}
            <div
              className={cx(styles.cardItem, styles.center, styles.help)}
              dangerouslySetInnerHTML={{
                __html: markdownToHtml(T('Web.LandingPage.Help')),
              }}
            />
          </div>

          <div className={cx(styles.cardItem, styles.footer, styles.center)}>
            {!branded.corporate && (
              <div>
                Copyright &copy; Tickmark, Inc {new Date().getFullYear()}
              </div>
            )}
          </div>
        </div>
      </CardView>
      <ImageView image={branded.image} />
    </div>
  )
})

const CardView = ({ children }) => (
  <div className={styles.cardView}>{children}</div>
)

const ImageView = ({ image }) => (
  <div
    className={styles.imageView}
    style={{
      backgroundImage: `url(${image})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    }}
  ></div>
)
