import { observable, action } from 'mobx'

export const SelectProviderType = {
  Reconnect: 'RECONNECT',
  Match: 'MATCH',
  Unknown: 'UNKNOWN',
}

export class SelectProviderDialogViewModel {
  /**
   * Whether the dialog is showing.
   */
  @observable showing = false

  /**
   * Which method the user has selected for provider selection.
   */
  @observable providerType = SelectProviderType.Unknown

  /**
   * Whether the user is selecting a previous provider, render that view.
   */
  @observable isSelectingPreviousProvider = false

  /**
   * The user public id of the provider that the user has selected.
   */
  @observable selectedProvider = null

  _resolve = null

  constructor({ job, projectStore, previousProviders }) {
    this.job = job
    this.projectStore = projectStore
    this.previousProviders = previousProviders
  }

  @action.bound
  setProviderType(providerType) {
    this.providerType = providerType
  }

  @action.bound
  continueAndSelectPreviousProvider() {
    this.isSelectingPreviousProvider = true
  }

  @action.bound
  selectProvider(provider) {
    this.selectedProvider = provider
  }

  @action.bound
  connectWithPro() {
    if (!this._resolve) {
      this.showing = false
    }
    if (this.isSelectingPreviousProvider) {
      this.projectStore
        .requestChampionProvider(
          this.job.id,
          this.selectedProvider?.user_public_id
        )
        .then(this.complete)
      return
    }
    this.complete()
  }

  @action.bound
  complete() {
    this.showing = false
    this._resolve(true)
  }

  @action.bound
  cancel() {
    this.showing = false
    if (this._resolve) {
      this._resolve(false)
    }
  }

  @action.bound
  show() {
    this.showing = true
    return new Promise((resolve) => {
      this._resolve = resolve
    })
  }

  @action.bound
  hide() {
    this.showing = false
  }
}
