import { action, observable, computed } from 'mobx'
import { task } from 'mobx-task'
import { validationContext } from 'validx'
import { extractMessageFromError } from 'utils/errorUtil'
import { TaxRefundDeliveryMethodType } from 'src/client/domain/tax-refund-method/TaxRefundMethodTypes'
import { validateObject } from 'src/client/utils/validx-validators'
import { DirectDepositFormViewModel } from '../DeliveryMethodForms/DirectDepositForm/DirectDepositForm'
import { CheckFormViewModel } from '../DeliveryMethodForms/CheckForm/CheckForm'

export class PersonalRefundMethodSupportingItemInputViewModel {
  /**
   * The underlying item.
   *
   * @type {RequestedSupportingItem}
   */
  @observable item

  /**
   * Whether the dialog form is showing.
   */
  @observable showingDialog = false

  @observable deliveryMethodInput = new DirectDepositFormViewModel()

  validation = validationContext(this, {
    deliveryMethodInput: [validateObject('deliveryMethodInput')],
  })

  constructor({
    item,
    flashMessageStore,
    taxRefundMethodStore,
    infoGatheringStore,
    getCurrentJob,
  }) {
    this.item = item
    this.flashMessageStore = flashMessageStore
    this.taxRefundMethodStore = taxRefundMethodStore
    this.infoGatheringStore = infoGatheringStore
    this.getCurrentJob = getCurrentJob
  }

  activate = task.resolved(async () => {
    await this.fetchPersonalTaxRefundMethod()
  })

  @computed
  get deliveryMethodTypeInput() {
    switch (true) {
      case this.deliveryMethodInput instanceof DirectDepositFormViewModel:
        return TaxRefundDeliveryMethodType.DirectDeposit
      case this.deliveryMethodInput instanceof CheckFormViewModel:
        return TaxRefundDeliveryMethodType.Check
      default:
        return TaxRefundDeliveryMethodType.DirectDeposit
    }
  }

  @computed
  get infoLines() {
    switch (this.taxRefundMethod.deliveryMethod.type) {
      case TaxRefundDeliveryMethodType.DirectDeposit:
        return {
          title: 'Direct deposit',
          description:
            'Provide your personal bank account information upfront. The tax preparer will share your account details with the IRS, to pay out refunds automatically.',
          contactInfo: [],
        }
      case TaxRefundDeliveryMethodType.Check:
        return {
          title: 'Paper check by mail',
          description:
            'Receive a refund as a paper check. This option takes longer to process.',
          contactInfo: this.toContactInfo(
            this.taxRefundMethod.deliveryMethod.check.mailingAddress
          ),
        }
      default:
        return {
          title: '',
          description: '',
          contactInfo: [],
        }
    }
  }

  toContactInfo(address) {
    if (!address) {
      return ['Using same address as in personal information.']
    }

    return [
      address.addressLine1,
      address.addressLine2,
      cityStateZip(address),
      address.country,
    ].filter(Boolean)

    function cityStateZip(address) {
      return [address.city, address.region, address.postalCode]
        .filter((line) => Boolean(line))
        .join(', ')
    }
  }

  @action.bound
  selectDeliveryMethod(deliveryMethodType) {
    switch (deliveryMethodType) {
      case TaxRefundDeliveryMethodType.DirectDeposit:
        this.deliveryMethodInput = new DirectDepositFormViewModel()
        break
      case TaxRefundDeliveryMethodType.Check:
        this.deliveryMethodInput = new CheckFormViewModel()
        break
      default:
        this.deliveryMethodInput = new DirectDepositFormViewModel()
        break
    }
  }

  /**
   * Assigns the fetched profile to the view model.
   */
  @action.bound
  fillRefundMethod() {
    if (!this.taxRefundMethod) {
      return
    }

    switch (this.taxRefundMethod.deliveryMethod.type) {
      case TaxRefundDeliveryMethodType.DirectDeposit:
        this.deliveryMethodInput = new DirectDepositFormViewModel()
        this.deliveryMethodInput.parse(
          this.taxRefundMethod.deliveryMethod.directDeposit.bankAccount
        )
        break
      case TaxRefundDeliveryMethodType.FutureTaxCredit:
        // Not supported for Personal Tax Refund Method
        break
      case TaxRefundDeliveryMethodType.Check:
        this.deliveryMethodInput = new CheckFormViewModel()
        this.deliveryMethodInput.parse(
          this.taxRefundMethod.deliveryMethod.check.mailingAddress
        )
        break
    }
  }

  /**
   * The current job.
   */
  @computed
  get currentJob() {
    return this.getCurrentJob()
  }

  /**
   * The current tax refund method for the job if any
   */
  @computed
  get taxRefundMethod() {
    return this.taxRefundMethodStore.getPersonalTaxReturnMethodVersion(
      this.item.input.refundMethodId,
      this.item.input.refundMethodVersion
    )
  }

  /**
   * Fetches the personal profile for the job.
   */
  @task
  async fetchPersonalTaxRefundMethod() {
    if (!this.item.input?.refundMethodId) {
      return
    }

    await this.taxRefundMethodStore
      .fetchPersonalTaxRefundMethodForJob(this.currentJob.id)
      .then(() => this.fillRefundMethod())
      .catch((e) => {
        this.flashMessageStore.create({
          message: extractMessageFromError(e),
          type: 'error',
        })

        throw e
      })
  }

  /**
   * Shows the personal information dialog.
   */
  @action
  showDialog() {
    this.showingDialog = true
  }

  /**
   * Closes the personal information dialog.
   */
  @action
  closeDialog() {
    this.showingDialog = false
  }

  /**
   * Submits the personal information.
   *
   * @returns
   */
  @task.resolved
  async submit() {
    if (!this.validation.reset().validate().isValid) {
      return
    }

    try {
      const result =
        await this.taxRefundMethodStore.createPersonalTaxRefundMethod(
          this.currentJob.workspaceId,
          this.deliveryMethodInput.toDto()
        )

      await this.infoGatheringStore.providePersonalRefundMethod(
        result.id,
        result.version,
        this.currentJob.id,
        this.item
      )
      this.closeDialog()
    } catch (ex) {
      this.flashMessageStore.create({
        type: 'error',
        message: extractMessageFromError(ex),
      })
    }
  }
}
