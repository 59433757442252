import { Store } from 'libx'
import PersonalTaxRefundMethodVersion from '../domain/tax-refund-method/PersonalTaxRefundMethodVersion'
import BusinessTaxRefundMethodVersion from '../domain/tax-refund-method/BusinessTaxRefundMethodVersion'

export default class TaxRefundMethodStore extends Store {
  personalTaxRefundMethods = this.collection({
    model: PersonalTaxRefundMethodVersion,
    getDataId: (data) => `${data.id}:${data.version}`,
    getModelId: (model) => `${model.id}:${model.version}`,
  })

  businessTaxRefundMethods = this.collection({
    model: BusinessTaxRefundMethodVersion,
    getDataId: (data) => `${data.id}:${data.version}`,
    getModelId: (model) => `${model.id}:${model.version}`,
  })

  constructor({ rootStore }) {
    super({ rootStore })
    this.api = rootStore.api
  }

  getPersonalTaxReturnMethodVersion(refundMethodId, refundMethodVersionId) {
    return this.personalTaxRefundMethods.get(
      `${refundMethodId}:${refundMethodVersionId}`
    )
  }

  getBusinessTaxReturnMethodVersion(refundMethodId, refundMethodVersionId) {
    return this.businessTaxRefundMethods.get(
      `${refundMethodId}:${refundMethodVersionId}`
    )
  }

  /**
   * Fetches a Personal Refund Method by Id
   * @param {string} refundMethodId
   * @returns
   */
  async fetchPersonalTaxRefundMethod(refundMethodId) {
    return this.api.taxRefundMethod
      .getTaxRefundMethod(refundMethodId)
      .then((p) => this.personalTaxRefundMethods.set(p))
  }

  /**
   * Fetches the personal tax refund method for a job.
   *
   * @param {*} jobId
   * @returns
   */
  async fetchPersonalTaxRefundMethodForJob(jobId) {
    return this.api.taxRefundMethod
      .getPersonalTaxRefundMethodForJob(jobId)
      .then((p) => this.personalTaxRefundMethods.set(p))
  }

  /**
   * Fetches a Business Refund Method by Id
   * @param {string} refundMethodId
   * @returns
   */
  async fetchBusinessTaxRefundMethod(refundMethodId) {
    return this.api.taxRefundMethod
      .getTaxRefundMethod(refundMethodId)
      .then((p) => this.businessTaxRefundMethods.set(p))
  }

  /**
   * Fetches the business tax refund method for a job.
   *
   * @param {*} jobId
   * @returns
   */
  async fetchBusinessTaxRefundMethodForJob(jobId) {
    return this.api.taxRefundMethod
      .getBusinessTaxRefundMethodForJob(jobId)
      .then((p) => this.businessTaxRefundMethods.set(p))
  }

  /**
   * Saves the personal tax refund method.
   *
   * @param {*} workspaceId
   * @param {*} deliveryMethodDto
   * @returns
   */
  async createPersonalTaxRefundMethod(workspaceId, deliveryMethodDto) {
    return this.api.taxRefundMethod
      .createPersonalTaxRefundMethod(workspaceId, deliveryMethodDto)
      .then((refundMethod) =>
        this.fetchPersonalTaxRefundMethod(refundMethod.id)
      )
  }

  async createBusinessTaxRefundMethod(workspaceId, deliveryMethodDto) {
    return this.api.taxRefundMethod
      .createBusinessTaxRefundMethod(workspaceId, deliveryMethodDto)
      .then((refundMethod) =>
        this.fetchBusinessTaxRefundMethod(refundMethod.id)
      )
  }

  async updatePersonalTaxRefundMethod(workspaceId, deliveryMethodDto) {
    return this.api.taxRefundMethod
      .updateTaxRefundMethod(workspaceId, deliveryMethodDto)
      .then((p) => this.personalTaxRefundMethods.set(p))
  }

  async updateBusinessTaxRefundMethod(workspaceId, deliveryMethodDto) {
    return this.api.taxRefundMethod
      .updateTaxRefundMethod(workspaceId, deliveryMethodDto)
      .then((p) => this.businessTaxRefundMethods.set(p))
  }
}
