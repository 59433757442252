import React from 'react'

import { observer } from 'mobx-react'
import Modal from 'components/Modal'
import { Dialog, DialogButton, DialogFooter } from 'components/Dialog'
import './styles.sass'

export default observer(function TermsDialog({ vm }) {
  return (
    <Modal
      isOpened={vm.showing}
      dismissOnOutsideClick={vm.optional}
      hideOverlayDismiss={!vm.optional}
      onRequestClose={() => vm.decline()}
    >
      <Dialog className="terms-dialog">
        <div className="iframe-container">
          <div className="terms-frame">
            <div
              className="terms-frame-content"
              dangerouslySetInnerHTML={{ __html: vm.termsContent }}
            ></div>
          </div>
        </div>
        <DialogFooter bordered>
          <DialogButton onClick={() => vm.accept()}>Accept</DialogButton>
          {vm.optional && (
            <DialogButton danger onClick={() => vm.decline()}>
              Decline
            </DialogButton>
          )}
        </DialogFooter>
      </Dialog>
    </Modal>
  )
})

export const ViewTermsDialog = observer(function ViewTermsDialog({
  isOpened,
  onRequestClose,
  terms,
}) {
  return (
    <Modal isOpened={isOpened} onRequestClose={() => onRequestClose()}>
      <Dialog className="terms-dialog">
        <div className="terms-frame">
          {terms && (
            <div
              className="terms-frame-content"
              dangerouslySetInnerHTML={{
                __html: terms.markup,
              }}
            ></div>
          )}
        </div>
        <DialogFooter bordered>
          <DialogButton onClick={() => onRequestClose()}>Done</DialogButton>
        </DialogFooter>
      </Dialog>
    </Modal>
  )
})
