import { action, observable, computed } from 'mobx'
import { task } from 'mobx-task'
import { validationContext, required } from 'validx'
import { extractMessageFromError } from 'utils/errorUtil'
import { PhysicalAddressFormViewModel } from '../../PhysicalAddressForm/PhysicalAddressFormViewModel'
import { obscureSsn } from 'src/client/utils/stringUtil'
import { validateObject } from 'utils/validx-validators'
import { PersonalInfoViewModel } from '../PersonalInfoViewModel'

/**
 * View model for managing the Personal Info supporting item input.
 */
export class PersonalInfoSupportingItemInputViewModel {
  /**
   * The underlying item.
   *
   * @type {RequestedSupportingItem}
   */
  @observable item

  /**
   * Whether the dialog form is showing.
   */
  @observable showingDialog = false

  @observable personalInfo = new PersonalInfoViewModel()
  @observable physicalAddressVM = new PhysicalAddressFormViewModel()
  @observable occupation
  @observable phoneNumber
  @observable email

  validation = validationContext(this, {
    occupation: [required('Occupation is required')],
    personalInfo: [validateObject('Personal information is required')],
    phoneNumber: [required('Phone number is required')],
    email: [required('Email is required')],
    physicalAddressVM: [validateObject('physicalAddressVM')],
  })

  constructor({
    item,
    flashMessageStore,
    individualProfileStore,
    infoGatheringStore,
    getCurrentJob,
    getCurrentMember,
  }) {
    /**
     * @type {RequestedSupportingItem}
     */
    this.item = item
    this.flashMessageStore = flashMessageStore
    this.individualProfileStore = individualProfileStore
    this.infoGatheringStore = infoGatheringStore
    this.getCurrentJob = getCurrentJob
    this.getCurrentMember = getCurrentMember
  }

  activate = task.resolved(async () => {
    this.prefillProfile()
    await this.fetchPersonalProfile()
  })

  /**
   * The current job.
   */
  @computed
  get currentJob() {
    return this.getCurrentJob()
  }

  /**
   * The current profile for the job if any
   */
  @computed
  get profile() {
    return this.individualProfileStore.getPersonalProfileVersion(
      this.item.input.profileId,
      this.item.input.profileVersion
    )
  }

  /**
   * Title of the item.
   *
   * @param {*} personalInfo
   * @returns
   */
  title(personalInfo) {
    return [
      personalInfo?.firstName,
      personalInfo?.middleInitial,
      personalInfo?.lastName,
    ]
      .filter(Boolean)
      .join(' ')
  }

  /**
   * The header description for the item.
   */
  @computed
  get headerDescription() {
    return [
      this.profile.personalInfo.dateOfBirth,
      obscureSsn(
        this.profile.personalInfo.personalTaxpayerIdentificationNumber
      ),
      this.profile.contactInfo.phoneNumber,
    ]
      .filter(Boolean)
      .join(' / ')
  }

  /**
   * The summary of the contact information.
   */
  @computed
  get contactInfoLines() {
    return [
      this.profile.contactInfo.addressLine1,
      this.profile.contactInfo.addressLine2,
      this.cityStateZip,
      this.profile.contactInfo.country,
    ].filter(Boolean)
  }

  /**
   * The summary of the city, state, and zip.
   */
  @computed
  get cityStateZip() {
    return [
      this.profile.contactInfo.city,
      this.profile.contactInfo.region,
      this.profile.contactInfo.postalCode,
    ]
      .filter(Boolean)
      .join(', ')
  }

  /**
   * Shows the personal information dialog.
   */
  @action
  showDialog() {
    this.showingDialog = true
  }

  /**
   * Closes the personal information dialog.
   */
  @action
  closeDialog() {
    this.showingDialog = false
  }

  /**
   * Fetches the personal profile for the job.
   */
  @task
  async fetchPersonalProfile() {
    if (!this.item.input?.profileId) {
      return
    }

    await this.individualProfileStore
      .fetchPersonalProfileForJob(this.currentJob.id)
      .then((p) => this.fillProfile(p))
      .catch((e) => {
        this.flashMessageStore.create({
          message: extractMessageFromError(e),
          type: 'error',
        })

        throw e
      })
  }

  /**
   * Submits the personal information.
   *
   * @returns
   */
  @task.resolved
  async submit() {
    if (!this.validation.reset().validate().isValid) {
      return
    }

    try {
      const result = await this.individualProfileStore.savePersonalProfile(
        this.currentJob.workspaceId,
        this.personalInfo.toDto(),
        {
          email: this.email,
          phoneNumber: this.phoneNumber,
          contactAddress: this.physicalAddressVM.addressDto,
        },
        this.occupation
      )

      await this.infoGatheringStore.providePersonalInfo(
        this.currentJob.id,
        this.item,
        result.id,
        result.version
      )
      this.closeDialog()
    } catch (ex) {
      this.flashMessageStore.create({
        type: 'error',
        message: extractMessageFromError(ex),
      })
    }
  }

  /**
   * Assigns the fetched profile to the view model.
   */
  @action.bound
  fillProfile() {
    if (!this.profile) {
      return
    }

    this.personalInfo.parse(this.profile.personalInfo)
    this.physicalAddressVM.parse(this.profile.contactInfo)
    this.occupation = this.profile.occupation
    this.email = this.profile.contactInfo?.email
    this.phoneNumber = this.profile.contactInfo?.phoneNumber
  }

  /**
   * Prefills the profile with the current member's information.
   */
  @action
  prefillProfile() {
    const member = this.getCurrentMember()
    this.personalInfo.parse({
      firstName: member.givenName,
      lastName: member.familyName,
    })
    this.email = member.email
    this.phoneNumber = member.phone
  }
}
