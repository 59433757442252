import { reaction, observable, action, computed, runInAction } from 'mobx'
import {
  isBefore,
  isSameDay,
  addDays,
  addMinutes,
  format,
  set,
  areIntervalsOverlapping,
} from 'date-fns'
import TimeRange from '@taxfyle/web-commons/lib/utils/TimeRange'
import { task } from 'mobx-task'
import { Store } from 'libx'
import moment from 'moment-timezone'
import { maybeParseDate } from 'utils/dateUtil'
import { timestampToISO } from '@taxfyle/web-commons/lib/utils/grpcUtil'
import memoize from 'memoizee'
import { defaultCancellationToken } from '@taxfyle/web-commons/lib/utils/cancellation'
import { getFeatureToggleClient } from 'misc/featureToggles'

/**
 * Fetches booked time slots for a pro.
 *
 * @param {String} proId - The ID of the pro.
 * @param {String} jobId - The ID of the job.
 * @param {Function} apiCall - Function to fetch time slots.
 * @returns {*}
 */
const fetchBookedTimeSlotsForPro = (proId, jobId, apiCall) =>
  apiCall(proId, jobId)
    .then((res) => res.getBookedTimesList())
    .then((trs) =>
      trs.map(
        (tr) =>
          new TimeRange(
            maybeParseDate(timestampToISO(tr.getStartTime())) ?? new Date(),
            maybeParseDate(timestampToISO(tr.getEndTime())) ?? new Date()
          )
      )
    )

/**
 * Fetches booked time slots for pro, with results cached.
 */
const fetchBookedTimeSlotsForProMemo = memoize(
  async (proId, jobId, apiCall) =>
    fetchBookedTimeSlotsForPro(proId, jobId, apiCall),
  {
    length: 2, // Only count the first two arguments for memoization. Third is a dependency.
    promise: true, // The function returns a promise.
    maxAge: 3000, // TTL is 3 seconds.
    max: 3, // Only 3 values should ever be cached at a given time.
  }
)

export default class ScheduleStore extends Store {
  /**
   * How many days get presented on the screen to pick times from.
   * @type {number}
   */
  daysShown = 7

  /**
   * The interval for each time slot. 60 minutes by default, but can change
   * depending on the consultation.
   * @type {number}
   */
  intervalInMinutes = 60

  /**
   * All the time slots available in the UI for the given day.
   * @type {[TimeRange]}
   */
  @observable
  timeSlots = []

  /**
   * List of references to all the available days. Each "ScheduledDay" holds the
   * user's individual time slot selections.
   * @type {[ScheduledDay]}
   */
  @observable
  scheduledDays = []

  /**
   * The current day we have selected. This is used to index the scheduledDays list.
   * @type {number}
   */
  @observable
  dayIndex = 0

  /**
   * Represents time ranges that have been booked by the pro, if any, on other jobs.
   * Includes the requested pro prior to pickup.
   * @type {[TimeRange]}
   */
  @observable
  timeRangesBookedByProForOtherJob = []

  constructor() {
    super(...arguments)
    this.reset()

    reaction(
      () => this.consultation,
      () => this.populateScheduledDays()
    )

    reaction(
      () => this.timeRangesBookedByProForOtherJob,
      () => this.removeSelectionsForTimeSlotsPreBookedByPro()
    )
  }

  /**
   * The currently selected day.
   * @returns {ScheduledDay}
   */
  @computed
  get selectedDay() {
    return this.scheduledDays[this.dayIndex]
  }

  /**
   * Time ranges that the user has selected (including previously set availability)
   * across all days in consolidated form. For example, if on Monday and Tuesday
   * the user had selected 8 AM to 9 AM and 9 AM to 10 AM, you'd get two time
   * ranges - Monday from 8 AM to 10 AM and Tuesday from 8 AM to 10 AM. This
   * "grouping" of the individual time slots is what we mean by "consolidated".
   * @returns {TimeRange[]}
   */
  @computed
  get selectedTimeRanges() {
    return this.scheduledDays.flatMap((sd) => sd.timeSlotsConsolidated)
  }

  /**
   * Indicates whether the user has selected any times, including via previous
   * availability selections.
   * @returns {boolean}
   */
  @computed
  get hasSelectedTimes() {
    return this.selectedTimeRanges.length > 0
  }

  /**
   * Days for which the user has selected at least one time slot.
   * @returns {ScheduledDay[]}
   */
  @computed
  get scheduledAvailability() {
    return this.scheduledDays.filter((d) => d.timeSlots.length > 0)
  }

  /**
   * The current consultation on the job.
   */
  @computed
  get consultation() {
    return this.rootStore.projectDetailsStore?.project?.consultation
  }

  /**
   * Indicates whether we're dealing with an active skip scheduling consultation.
   * @returns {boolean}
   */
  @computed
  get skipSchedulingAndActive() {
    return (
      this.consultation?.skipScheduling &&
      (this.consultation?.status === 'PENDING_CALL' ||
        this.consultation?.status === 'IN_PROGRESS')
    )
  }

  @computed
  get hasDiyForCurrentYear() {
    return this.rootStore.projectDetailsStore?.hasDiyForCurrentYear
  }

  /**
   * Booking details.
   * @returns {string}
   */
  @computed
  get bookingInformation() {
    const bookedTime = this.consultation?.bookedTime

    if (this.consultation?.skipScheduling) {
      if (this.consultation.status === 'DONE') {
        return ''
      }

      return `Message your Pro to schedule a consultation call.`
    }

    if (!bookedTime) {
      return `We have shared your updated availability with your Pro! As soon as
      they select a new date and time, we will notify you.`
    }
    const date = format(bookedTime.startTime, 'EEEE, MMMM do')
    const startTime = format(bookedTime.startTime, 'h:mm')
    const endTime = format(bookedTime.endTime, 'h:mm a')
    const timeZone = moment.tz(moment.tz.guess()).zoneAbbr()

    if (this.consultation?.status === 'DONE') {
      return `Call took place on ${date} at ${startTime} - ${endTime} ${timeZone}.`
    }

    return `Call scheduled for ${date} at ${startTime} - ${endTime} ${timeZone}.`
  }

  /**
   * Activates the store.
   * @returns {Promise<void>}
   */
  @action.bound
  async activate() {
    this.reset()
    this.fetchBookedTimeRangesForPro(defaultCancellationToken).then()
  }

  /**
   * Sets up the next 5 days and Populates the user's previously selected availability.
   */
  @action.bound
  populateScheduledDays() {
    this.scheduledDays = []
    this.intervalInMinutes = this.consultation?.requestedLength || 60
    const currentDate = new Date()
    const nextHour = addMinutes(currentDate, 60).getHours()
    const cutoffHour = 19 // cuts off scheduling for the current day at 7PM

    // Calculate which day is the first day the user will be allowed to schedule times.
    const startDate = this.hasDiyForCurrentYear
      ? nextHour >= cutoffHour
        ? addDays(currentDate, 1)
        : currentDate
      : addDays(currentDate, 1)

    const prevAvailability = this.consultation?.availability?.flatMap((a) =>
      a.decomposeRangeInIntervals(this.intervalInMinutes)
    )

    // For each day, select the relevant time slots that the user has previously (if any)
    // selected.
    let date = startDate
    while (this.scheduledDays.length < this.daysShown) {
      const sd = new ScheduledDay(date)

      // Every previous availability time slot needs to be selected, but we only want to
      // select it for a given day if it occurs on that day.
      prevAvailability?.forEach((a) => {
        if (
          isSameDay(date, a.startTime) &&
          !this.isAlreadyBookedByProForOtherJob(a)
        ) {
          sd.selectTimeSlot(a)
        }
      })

      this.scheduledDays.push(sd)
      date = addDays(date, 1)
    }

    this.populateTimeSlotsForFirstDay()
  }

  /**
   * Generates time slots for the UI for a given day.
   * @param {Date} startDate
   * @param {number} startingHour
   * @param {number} startingMinute
   */
  @action.bound
  populateTimeSlots(startDate, startingHour, startingMinute) {
    const from = set(startDate, {
      hours: startingHour,
      minutes: startingMinute,
      seconds: 0,
      milliseconds: 0,
    })

    const to = set(startDate, {
      hours: 21,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    })

    this.timeSlots = []
    let cursor = from
    while (isBefore(cursor, to)) {
      const end = addMinutes(cursor, this.intervalInMinutes)
      this.timeSlots.push(new TimeRange(cursor, end))
      cursor = end
    }
  }

  /**
   * Get the next available day.
   */
  @action.bound
  nextDay() {
    this.dayIndex += 1
    this.dayIndex %= this.daysShown
  }

  /**
   * Get the previous available day.
   */
  @action.bound
  prevDay() {
    this.dayIndex += -1 + this.daysShown
    this.dayIndex %= this.daysShown
  }

  /**
   * Sets the selected date.
   *
   * @param {*} index
   */
  @action.bound
  setDay(index) {
    this.dayIndex = index

    if (index !== 0) {
      this.populateTimeSlots(this.selectedDay.day, 8, 0)
    } else {
      this.populateTimeSlotsForFirstDay()
    }
  }

  /**
   * Reloads the scheduled days list.
   */
  @action.bound
  reset() {
    this.dayIndex = 0

    // Set up the next 5 days excluding weekends.
    this.populateScheduledDays()
  }

  /**
   * Sets the user's availability given their consolidated time ranges.
   * @returns {Promise<void>}
   */
  @task
  async setConsultationAvailability() {
    await this.rootStore.projectDetailsStore.setConsultationAvailability(
      this.selectedTimeRanges
    )
  }

  /**
   * Special case handling of selectable time slots for the first day. Ultimately
   * generates the time slots that are available in the UI for the first day.
   */
  @action
  populateTimeSlotsForFirstDay() {
    const currentDate = this.selectedDay.day
    const nextHour = addMinutes(currentDate, 60).getHours()
    const cutoffHour = 19 // cuts off scheduling for the current day at 7PM

    /**
     * If we have DIY, start from the next reasonable hour.
     * Otherwise, start at 8 AM
     */
    const startingHour = this.hasDiyForCurrentYear
      ? nextHour >= cutoffHour
        ? 8
        : nextHour
      : 8

    /**
     * If we have DIY, start at the next reasonable 30 minute interval.
     * Otherwise, start at 0 minutes.
     */
    const startingMinute = this.hasDiyForCurrentYear
      ? currentDate.getMinutes() >= 30
        ? 30
        : 0
      : 0

    const firstTimeSlot =
      this.selectedDay.timeSlots.length > 0
        ? this.selectedDay.timeSlots[0]
        : null

    if (
      firstTimeSlot &&
      isBefore(
        firstTimeSlot.startTime,
        set(currentDate, {
          hours: startingHour,
          minutes: startingMinute,
          seconds: 0,
          milliseconds: 0,
        })
      )
    ) {
      this.populateTimeSlots(
        currentDate,
        firstTimeSlot.startTime.getHours(),
        firstTimeSlot.startTime.getMinutes()
      )
      return
    }

    this.populateTimeSlots(currentDate, startingHour, startingMinute)
  }

  /**
   * Fetches active time ranges that the pro has already booked on other jobs
   * (but not this job).
   * @returns {Promise<void>}
   */
  @task
  async fetchBookedTimeRangesForPro(ct, skipCache = false) {
    const showGreyedTimeSlots = getFeatureToggleClient().variation(
      'CustomerPortal.ShowGreyedTimeSlots',
      false
    )

    if (!showGreyedTimeSlots) {
      return runInAction(() => {
        this.timeRangesBookedByProForOtherJob = []
      })
    }

    if (ct?.isCancelled) {
      return
    }

    const project = this.rootStore.projectDetailsStore.project
    const consultation = project?.consultation

    // If there's no project or consultation, there should be no booked slots.
    if (!project || !consultation) {
      return runInAction(() => {
        this.timeRangesBookedByProForOtherJob = []
      })
    }

    // If the consultation isn't active, there should be no booked slots.
    if (
      consultation.status === 'DONE' ||
      consultation.status === 'CANCELLED' ||
      consultation.status === 'IN_PROGRESS'
    ) {
      return runInAction(() => {
        this.timeRangesBookedByProForOtherJob = []
      })
    }

    const requestedProId = project.requestedProPublicId
    const proMemberId = project.members.find(
      (m) => m.type === 'PROVIDER' && m.role === 'CHAMPION'
    )?.user?.userPublicId

    const proId = proMemberId ?? requestedProId

    // If there is no pro, there should be no booked slots.
    if (!proId) {
      return runInAction(() => {
        this.timeRangesBookedByProForOtherJob = []
      })
    }

    const timeRanges = skipCache
      ? await fetchBookedTimeSlotsForPro(proId, project.id, (pId, jId) =>
          this.rootStore.api.consultations.listBookedTimeRangesForPro(pId, jId)
        )
      : await fetchBookedTimeSlotsForProMemo(proId, project.id, (pId, jId) =>
          this.rootStore.api.consultations.listBookedTimeRangesForPro(pId, jId)
        )

    if (ct?.isCancelled) {
      return
    }

    runInAction(() => {
      this.timeRangesBookedByProForOtherJob = timeRanges
    })
  }

  /**
   * Indicates whether the given time range is already booked by the pro.
   * @param {TimeRange} timeRange - The time range to test.
   */
  isAlreadyBookedByProForOtherJob(timeRange) {
    return this.timeRangesBookedByProForOtherJob.some((tr) =>
      areIntervalsOverlapping(
        {
          start: timeRange.startTime,
          end: timeRange.endTime,
        },
        {
          start: tr.startTime,
          end: tr.endTime,
        }
      )
    )
  }

  /**
   * Removes user time slot selections that have already been booked by the pro
   * on other jobs.
   */
  @action
  removeSelectionsForTimeSlotsPreBookedByPro() {
    return this.scheduledDays.forEach((day) =>
      day.timeSlots
        .filter((s) => this.isAlreadyBookedByProForOtherJob(s))
        .forEach((s) => day.selectTimeSlot(s))
    )
  }

  /**
   * Indicates whether booked time information should be automatically fetched
   * on an internal.
   * @returns {boolean}
   */
  shouldRefetch() {
    return this.rootStore.projectDetailsStore.isConsultationPendingAvailability
      ? true
      : this.rootStore.projectDetailsStore.selectScheduleDialogViewModel.showing
  }
}

/**
 * A day which maintains a list of selected time slots.
 */
class ScheduledDay {
  /**
   * Reference to the underlying date object that represents this day.
   * @type {Date}
   */
  @observable
  day

  /**
   * The time slots the user has selected for this day.
   * @type {[TimeRange]}
   */
  @observable
  timeSlots = []

  /**
   * @param {Date} day - A date object representing the day.
   */
  constructor(day) {
    this.day = day
  }

  /**
   * All selected time slots for this day in consolidated form. Overlapping or
   * continuous selected time slots are grouped into larger ones. For example, if
   * the user selected 8 AM to 9 AM and 9 AM to 10 AM, you'd get one time range
   * from 8 AM to 10 AM. This "grouping" of individual time slots is what we mean
   * by "consolidated".
   * @returns {[TimeRange]|*}
   */
  @computed
  get timeSlotsConsolidated() {
    const sorted = this.timeSlots
      .slice()
      .sort((a, b) => a.startTime.getTime() - b.startTime.getTime())

    return sorted.reduce((accum, curr) => {
      if (accum.length === 0) {
        return [curr]
      }

      const prev = accum.pop()
      // Current range is inside the previous
      if (curr.endTime <= prev.endTime) {
        return [...accum, prev]
      }

      // Ranges are overlapping or continuous
      if (curr.startTime <= prev.endTime) {
        return [...accum, new TimeRange(prev.startTime, curr.endTime)]
      }

      // Nothing overlapping, move along
      return [...accum, prev, curr]
    }, [])
  }

  /**
   * Represents a user selecting a time slot, either to select or deselect it.
   * If it wasn't previously selected, it'll be added to the list of time slots.
   * If it was previously selected, it'll be removed from the list of time slots.
   *
   * @param {TimeRange} slot
   */
  @action
  selectTimeSlot(slot) {
    // You can't select a date that has passed
    if (isBefore(slot.startTime, new Date())) {
      return
    }

    const existingTime = this.timeSlots.findIndex((t) => t.isEqual(slot))
    if (existingTime > -1) {
      this.timeSlots.splice(existingTime, 1)
    } else {
      // Ensure the saved time slot has the correct day
      this.timeSlots.push(this.timeRangeForCurrentDay(slot))
    }
  }

  /**
   * Creates a TimeRange from a time slot for the current day.
   *
   * @param {*} slot
   * @returns {TimeRange}
   */
  timeRangeForCurrentDay(slot) {
    return new TimeRange(
      set(new Date(this.day), {
        hours: slot.startTime.getHours(),
        minutes: slot.startTime.getMinutes(),
        seconds: 0,
        milliseconds: 0,
      }),
      set(new Date(this.day), {
        hours: slot.endTime.getHours(),
        minutes: slot.endTime.getMinutes(),
        seconds: 0,
        milliseconds: 0,
      })
    )
  }
}
