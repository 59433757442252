import LandingPage from 'components/LandingPage'
import PropTypes from 'prop-types'
import React from 'react'
import { getLogo } from 'utils/partnerUtil'
import trackUrlConversion from 'utils/trackUrlConversion'
import ContentLoading from 'components/ContentLoading'
import './styles.sass'

export default class LoginScreen extends React.Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  }

  login = () => {
    return this.props.rootStore.authStore.login().then(this.afterAuthenticate)
  }

  signup = () => {
    return this.props.rootStore.authStore
      .login('signUp')
      .then(this.afterAuthenticate)
      .then(trackUrlConversion)
  }

  afterAuthenticate = (success) => {
    if (!success) {
      return
    }

    const { location } = this.props
    if (location.state && location.state.nextPathname) {
      this.context.router.replace(location.state.nextPathname)
    } else {
      this.context.router.replace('/')
    }
  }

  render() {
    const store = this.props.rootStore.authStore

    const externalCustomersEnabled =
      this.props.rootStore.hydratedWorkspaceConfig?.external_customers_config
        ?.enabled ?? false

    if (externalCustomersEnabled) {
      // TODO: Before redirecting we need to be sure that we set the return
      // options in the session storage
      return window.location.replace('/__session/expired')
    }

    const { location } = this.props

    // so instead of showing the LandingPage we just show a Spinner
    // we will get automaticlly redirected in the next call since returnUrl not empty
    // also wanna make sure we only do that if there is a connection param in the url
    const returnUrl = window.localStorage.getItem('returnUrl')
    if (returnUrl && returnUrl.indexOf('connection') > -1) {
      return <ContentLoading small />
    }
    const fromInvite =
      location.state &&
      location.state.nextPathname &&
      location.state.nextPathname.includes('invitation')

    // if we're coming from the mobile app, only show a spinner on this page
    const origin = window.localStorage.getItem('accessOrigin')
    if (origin === 'mobile') {
      return <ContentLoading small />
    }

    return (
      <LandingPage
        rootStore={this.props.rootStore}
        onSignupClick={this.signup}
        onLoginClick={this.login}
        fromInvite={fromInvite}
        partnerLogo={getLogo(
          store.referrerParams && store.referrerParams.referrer
        )}
      />
    )
  }
}
