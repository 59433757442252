import { observable } from 'mobx'
import { Model } from 'libx'
import { maybeParseDate } from 'utils/dateUtil'
import { BankAccountType as BankAccountTypeProto } from '@taxfyle/api-internal/internal/tax_refund_methods_pb'
import {
  BankAccountType,
  TaxRefundDeliveryMethodType,
} from './TaxRefundMethodTypes'

export default class BusinessTaxRefundMethodVersion extends Model {
  @observable
  id = ''

  @observable
  version = 0

  @observable
  workspaceId = ''

  @observable
  ownerUserId = ''

  @observable
  deliveryMethod = {}

  @observable
  createTime = new Date()

  @observable
  updateTime = new Date()

  parse(json) {
    return {
      id: json.id,
      version: json.version,
      workspaceId: json.workspaceId,
      ownerUserId: json.ownerUserId,
      deliveryMethod: this.fromDeliveryMethodDTO(json.deliveryMethod),
      createTime: maybeParseDate(json.createTime),
      updateTime: maybeParseDate(json.updateTime),
    }
  }

  fromDeliveryMethodDTO(deliveryMethod) {
    switch (true) {
      case !!deliveryMethod.check:
        return {
          type: TaxRefundDeliveryMethodType.Check,
          check: {
            mailingAddress: deliveryMethod.check.mailingAddress
              ? {
                  addressLine1:
                    deliveryMethod.check.mailingAddress?.addressLine1,
                  addressLine2:
                    deliveryMethod.check.mailingAddress?.addressLine2,
                  city: deliveryMethod.check.mailingAddress?.city,
                  region: deliveryMethod.check.mailingAddress?.region,
                  postalCode: deliveryMethod.check.mailingAddress?.postalCode,
                  country: deliveryMethod.check.mailingAddress?.country,
                }
              : null,
          },
        }
      case !!deliveryMethod.directDeposit:
        return {
          type: TaxRefundDeliveryMethodType.DirectDeposit,
          directDeposit: {
            bankAccount: {
              accountType: this.fromBankAccountTypeDTO(
                deliveryMethod.directDeposit.bankAccount.accountType
              ),
              accountNumber:
                deliveryMethod.directDeposit.bankAccount.accountNumber,
              routingNumber:
                deliveryMethod.directDeposit.bankAccount.routingNumber,
            },
          },
        }
      case !!deliveryMethod.futureTaxCredit:
        return {
          type: TaxRefundDeliveryMethodType.FutureTaxCredit,
        }
      default:
        return {
          type: TaxRefundDeliveryMethodType.Unspecified,
        }
    }
  }

  fromBankAccountTypeDTO(accountTypeProto) {
    switch (accountTypeProto) {
      case BankAccountTypeProto.CHECKING:
        return BankAccountType.Checking
      case BankAccountTypeProto.SAVINGS:
        return BankAccountType.Savings
      default:
        return BankAccountType.Unspecified
    }
  }
}
